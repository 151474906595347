import React, { useEffect, useState } from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import AssignmentHeader from "./AssignmentHeader";
import AssignmentFooter from "./AssignmentFooter";
import AssignmentBasicInformation from "./AssignmentBasicInformation";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useQuery from "hooks/useQuery";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import PageTitle from "utils/PageTitle";

const schema = yup
  .object({
    allowView: yup.boolean().required("This field is required."),
  })
  .required();

export default function AddAssignment() {
  const [collapseState, setCollapseState] = useState(false);
  const [menuState, setMenuState] = useState([]);
  const location = useLocation();

  const navigate = useNavigate();
  const formId = useQuery().get("id");

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    console.log(data);

    // let myData = [{ ...data }];

    // myData.forEach((e) => {
    //   e.roleId = location?.state ? location?.state?.id : 0;
    // });

    // console.log(myData);

    // let myData = { ...data };

    // {
    //   myData.id = location?.state ? location?.state?.id : 0;
    // }
    // console.log(myData);

    // try {
    //   await RoleAPI.post(myData);
    //   toast.success("Posted", { position: "top-right", autoClose: 2000 });

    //   // ------- Get all object keys form data and set empty values to reset ------------
    //   const keys = Object.keys(data);
    //   for (const key of keys) {
    //     submittedValues[key] = "";
    //   }
    //   reset({ ...submittedValues });
    //   navigate(-1);
    // } catch (error) {
    //   toast.error("Something Wrong", {
    //     position: "top-right",
    //     autoClose: 2000,
    //   });
    //   console.log(error);
    // }
  };

  useEffect(() => {
    if (location.state) {
      reset(location.state);
    }
  }, []);

  return (
    <>
      <PageTitle name="Permissions" />
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <AssignmentHeader />
            </Col>
            <Col lg={12}>
              <AssignmentBasicInformation />
            </Col>
            {/* <Col xs={12}>
              <AssignmentFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
}
