import React from "react";
import { Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";

function ContactsInformation() {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Card.Header as="h6" className="bg-body-tertiary">
        Contact information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.contactName}
                  {...register("contactName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.contactName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Phone"
                className=""
              >
                <Form.Control
                  type="phone"
                  placeholder=""
                  isInvalid={!!errors.phone}
                  {...register("phone")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Mobile"
                className=""
              >
                <Form.Control
                  type="phone"
                  placeholder=""
                  isInvalid={!!errors.mobile}
                  {...register("mobile")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mobile?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className=""
              >
                <Form.Control
                  type="email"
                  placeholder=""
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Designation"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.designation}
                  {...register("designation")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.designation?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Gender">
                <Form.Select
                  aria-label="gender"
                  isInvalid={!!errors.gender}
                  {...register("gender")}
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.gender?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Date of Birth"
                className=""
              >
                <Form.Control
                  type="date"
                  placeholder=""
                  isInvalid={!!errors.dob}
                  {...register("dob")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dob?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Address"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.address}
                  {...register("address")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Country"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.country}
                  {...register("country")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.country?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="State"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.state}
                  {...register("state")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.state?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel controlId="floatingInput" label="Zip" className="">
                <Form.Control
                  type="number"
                  placeholder=""
                  isInvalid={!!errors.zip}
                  {...register("zip")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zip?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>

          {/*   <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
      </Card.Body>
    </>
  );
}

export default ContactsInformation;
