import { yupResolver } from "@hookform/resolvers/yup";
import { LibraryAPI, LibraryWithDetailsAPI } from "api";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customSettings } from "utils/customConfig";
import PageTitle from "utils/PageTitle";
import * as yup from "yup";
import SpaceTypeBasicInformation from "./SpaceTypeBasicInformation";
import SpaceTypeHeader from "./SpaceTypeHeader";

const schema = yup
  .object({
    parentId1: yup.string().required("This field is required."),
    name: yup.string().required("This field is required."),
    strength: yup.string().required("This field is required."),
    details: yup.array().of(
      yup.object().shape({
        field1: yup.string().required("AuthorityName is required"),
        field2: yup.string().required("Advertisement Category is required"),
        field3: yup.string().required("Rate is required"),
        field4: yup.string().required("duration is required"),
      })
    ),
    active: yup.boolean().required("This field is required."),
    // userName: yup.string().required("This field is required."),
    // email: yup.string().required("This field is required."),
    // password: yup.string().required("This field is required."),
    // role: yup.string().required("This field is required."),
  })
  .required();

const AddSpaceType = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      details: [{}],
    },
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = {
      ...data,
      id: location?.state?.id ?? 0, // Use nullish coalescing to handle undefined or null
      library: 7,
      phone: "Phone",
      email: "Email",
      logo: "Logo",
      level: "",
      type: "",
    };

    console.log(myData);

    try {
      await LibraryWithDetailsAPI.post(myData);
      toast.success("Posted", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      reset(location.state);
    }
  }, []);

  return (
    <>
      <PageTitle name="Space Type" />
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <SpaceTypeHeader />
            </Col>
            <Col lg={12}>
              <SpaceTypeBasicInformation />
            </Col>
            {/* <Col xs={12}>
              <CategoryFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddSpaceType;
