import React from "react";
import { useDraggable } from "@dnd-kit/core";

const Draggable = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({ id });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    padding: "2px",
    width: "200px",
    // border: "1px solid gray",
    marginBottom: "8px",
    backgroundColor: "white",
    cursor: "grab",
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

export default Draggable;

// Draggable.jsx
// import React from "react";
// import { useDraggable } from "@dnd-kit/core";

// const Draggable = ({ id, children }) => {
//   const { attributes, listeners, setNodeRef, transform } = useDraggable({
//     id,
//   });

//   const style = {
//     transform: transform
//       ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
//       : undefined,
//     cursor: "grab",
//     padding: "8px",
//     margin: "8px",
//     backgroundColor: "#f0f0f0",
//     border: "1px solid #ccc",
//     borderRadius: "4px",
//   };

//   return (
//     <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
//       {children}
//     </div>
//   );
// };

// export default Draggable;
