import styled from "styled-components";
import "./styles.css";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react"; // AG Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import {
  Button,
  Card,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import {
  clearLocalStorage,
  getLocalStorage,
  setLocalStorage,
} from "./utils/StoreState";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import IconButton from "components/common/IconButton";
import { useNavigate } from "react-router-dom";
import CardDropdown from "components/common/CardDropdown";
import { MdDeleteOutline, MdOutlineModeEdit } from "react-icons/md";
import TableHeaderDropdown from "components/common/TableHeaderDropdown";
import { LibraryAPI } from "api";
import { Checkbox } from "@mui/material";
import PageTitle from "utils/PageTitle";

export default function RegionsTable() {
  let isDarkBool = localStorage.getItem("isDark") === true;
  let isDarkTheme = localStorage.getItem("theme") === "dark";
  let auto = localStorage.getItem("theme") === "auto";
  const navigate = useNavigate();
  const columnApiRef = useRef();
  const gridRef = useRef();
  // let gridApi;
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [selectedItems, setSelectedItems] = useState([]);
  const [columnCustom, setColumnCustom] = useState([]);
  const [theme, setTheme] = useState();
  // const [gridApi, setGridApi] = useState();
  // const [columnApi, setColumnApi] = useState();

  const [rowData, setRowData] = useState([]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs, setColDefs] = useState([
    // { field: "", filter: false, headerName: "Select" },

    {
      field: "type",
      headerName: "Type",
      // checkboxSelection: true,
      // headerCheckboxSelection: true,
      // rowDrag: true,
      hide: false,
    },
    { field: "name", headerName: "Name", hide: false },
    {
      field: "active",
      headerName: "Active",
      hide: false,
      cellStyle: {
        display: "flex",
        paddingRight: "30px",
        justifyContent: "center",
      },
      headerClass: "center-header",
      cellRenderer: (p) => <Checkbox checked={p?.data.active} />,
    },
    {
      headerName: "Action",
      field: "action",
      maxWidth: 120,
      filter: false,
      cellRenderer: (p) => (
        <CardDropdown>
          <div className="py-2">
            <Dropdown.Item
              // as={Link}
              onClick={() => handleRowClick(p.data)}
              // href="/products/add-product"
              className="d-flex align-items-center gap-2 py-0"
            >
              <MdOutlineModeEdit />
              Edit
            </Dropdown.Item>
            {/* <Dropdown.Item
              //   onClick={() => handleDelete(row)}
              className="d-flex align-items-center gap-2 py-0"
            >
              <MdDeleteOutline />
              Delete
            </Dropdown.Item> */}
          </div>
        </CardDropdown>
      ),
    },
  ]);

  const defaultColDef = {
    sortable: true,
    filter: true,
    // floatingFilter: true,
    flex: 1,
    // rowDrag: true,
  };

  const handleRowClick = (data) => {
    if (data) {
      navigate(`/libraries/regions/add-new-region`, { state: data });
    }
  };

  //  ========== Export as CSV Start =============

  // const forExportData = (e) => {
  //   gridApi = e.api;
  // };
  // const onExportClick = () => {
  //   gridApi.exportDataAsCsv();
  // };
  //  ========== Export as CSV End =============

  //   ===============Custom Api Call start================

  let gridApi;
  let columnApi;

  const onGridReady = useCallback(
    (e) => {
      gridApi = e.api;
      columnApi = e.columnApi;
      restoreState();
      LibraryAPI.get({ params: { library: 6 } }).then((res) => {
        e.api.applyTransaction({ add: res?.data?.data });
      });
    },
    [window]
  );

  //   ===============Custom Api Call End================
  const onRowSelected = (e) => {
    // console.log(e.api.getSelectedRows());
  };

  const paginationPageSizeSelector = [10, 20, 50, 100, 200, 500, 1000];

  const saveState = useCallback(() => {
    const colState = columnApiRef.current.api.getColumnState();
    setLocalStorage(colState);
    console.log("column state saved");
  }, []);

  const restoreState = useCallback(() => {
    const colState = getLocalStorage();
    if (!colState) {
      console.log("no columns state to restore by, you must save state first");
      return;
    }
    columnApiRef.current.api.applyColumnState({
      state: colState,
      applyOrder: true,
    });
    console.log("column state restored");
  }, []);

  const resetState = useCallback(() => {
    columnApiRef.current.api.resetColumnState();
    clearLocalStorage();
    console.log("column state reset");
  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    columnApiRef.current.api.setGridOption(
      "quickFilterText",
      document.getElementById("filter-text-box").value
    );
  }, []);

  // useEffect(() => {
  //   let isDarkBool = localStorage.getItem("isDark") === true;
  //   let isDarkTheme = localStorage.getItem("theme") === "dark";
  //   let auto = localStorage.getItem("theme") === "auto";

  //   if (isDarkBool || isDarkTheme || auto) {
  //     setTheme("ag-theme-quartz-dark");
  //   } else {
  //     setTheme("ag-theme-quartz");
  //   }
  // }, [localStorage.getItem("isDark") || localStorage.getItem("theme")]);

  // console.log(theme);

  return (
    <>
      <PageTitle name="Regions" />
      <div className="py-2">
        <BreadCrumb />
      </div>

      <div
        className={`${
          isDarkBool || isDarkTheme || auto
            ? "ag-theme-quartz-dark"
            : "ag-theme-quartz"
        } rounded-0`} // applying the grid theme
        style={{ height: "100vh" }} // the grid will fill the size of the parent container
      >
        <Card className=" h-100">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">Regions</h5>

            <div className="d-flex gap-2">
              <OverlayTrigger
                overlay={
                  <Tooltip
                    style={{ position: "fixed" }}
                    id="overlay-trigger-example"
                  >
                    New
                  </Tooltip>
                }
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  onClick={() => navigate("/libraries/regions/add-new-region")}
                >
                  <span className="d-none d-sm-inline-block ms-1">New</span>
                </IconButton>
              </OverlayTrigger>
              <TableHeaderDropdown>
                <div className="py-2">
                  <Dropdown.Item
                    onClick={saveState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Save State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={restoreState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Restore State
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={resetState}
                    className="d-flex align-items-center gap-2 py-2"
                  >
                    Reset State
                  </Dropdown.Item>
                </div>
              </TableHeaderDropdown>
            </div>
            {/* <div className="test-header">
              <div className="example-header">
                <input
                  type="text"
                  id="filter-text-box"
                  placeholder="Search"
                  onInput={onFilterTextBoxChanged}
                />
              </div>
              <div className="example-section">
                <button onClick={saveState}>Save State</button>
                <button onClick={restoreState}>Restore State</button>
                <button onClick={resetState}>Reset State</button>
              </div>
            </div> */}
          </Card.Header>

          <Card.Body className="p-0">
            {/* <button onClick={() => onExportClick()}>abc</button> */}

            <AgGridReact
              // rowData={rowData}
              ref={columnApiRef}
              onGridReady={onGridReady}
              columnDefs={colDefs}
              defaultColDef={defaultColDef}
              onRowDoubleClicked={(params) => handleRowClick(params.data)}
              // pagination={true}
            />
          </Card.Body>
        </Card>
      </div>
    </>
  );
}
