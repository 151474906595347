import { useQuery } from "@tanstack/react-query";
import { MenusAPI, RolePermissionsAPI } from "api";
import { useFetchMenus } from "hooks/useFetchMenu";
import { useFetchRolesPermissions } from "hooks/useFetchPermissions";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

export const useFilterAndSortMenuItems = () => {
  // const [permissionsData, setPermissionsData] = useState([]);
  // const [menuData, setMenuData] = useState([]);

  const menuData = JSON.parse(localStorage.getItem("menus"));
  const userInfo = JSON.parse(localStorage.getItem("user"));
  let roleId = userInfo ? userInfo.role : 0;

  // const fetchMenu = async () => {
  //   try {
  //     await MenusAPI.get().then((res) => setMenuData(res?.data?.data));
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const { data: menuData, isLoading } = useFetchMenus();
  const { data: permissionsResponse, isLoading: isLoadingPermissions } =
    useFetchRolesPermissions(roleId);

  let permissionsData = permissionsResponse?.data.data ?? [];

  // const fetchPermissions = async () => {
  //   try {
  //     await RolePermissionsAPI.get({ params: { roleId } }).then((res) =>
  //       setPermissionsData(res?.data?.data)
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   // fetchMenu();
  //   fetchPermissions();
  // }, []);

  // Filter permissions by role
  //   const rolePermissions = permissionsData.filter(
  //     (permission) => permission.roleId === roleId
  //   );

  //   console.log("Role Permissions:", rolePermissions);

  //   // Create a set of menuIds that the user is allowed to view
  //   const allowedMenuIds = new Set(
  //     rolePermissions
  //       .filter((permission) => permission.allowView)
  //       .map((permission) => permission.menuId)
  //   );

  //   console.log("Allowed Menu IDs:", Array.from(allowedMenuIds));

  //   // Separate parent and child menu items
  //   //   const parents = menuData.filter(
  //   //     (menu) => menu.parentId === 0 && allowedMenuIds.has(menu.id)
  //   //   );

  //   const parents = menuData.filter((item) => item.parentId === 0);
  //   const children = menuData.filter(
  //     (menu) => menu.parentId !== 0 && allowedMenuIds.has(menu.id)
  //   );

  //   console.log("Parent Items:", parents);
  //   console.log("Child Items:", children);

  //   // Sort the parent items
  //   const sortedParents = parents.sort((a, b) => a.sortOrder - b.sortOrder);
  //   console.log("sorted parents", sortedParents);

  //   // Nest the child items under their respective parents
  //   const nestedMenuItems = sortedParents.map((parent) => {
  //     return {
  //       ...parent,
  //       children: children
  //         .filter((child) => child.parentId === parent.id)
  //         .sort((a, b) => a.sortOrder - b.sortOrder),
  //     };
  //   });

  //   console.log("Nested Menu Items:", nestedMenuItems);

  //   return nestedMenuItems;

  // Filter permissions by role
  const rolePermissions = permissionsData?.filter(
    (permission) => permission.roleId === roleId
  );

  //   console.log("Role Permissions", rolePermissions);

  // Create a map of menuId to permissions
  const menuPermissions = rolePermissions?.reduce((acc, permission) => {
    acc[permission.menuId] = permission;
    return acc;
  }, {});

  //   console.log("menu Permissions", rolePermissions);

  // Separate parent and child menu items
  const parents = menuData?.data?.data.filter((menu) => menu.parentId === 0);
  const children = menuData?.data?.data.filter((menu) => menu.parentId !== 0);

  // Map parent IDs to their children
  const parentIdToChildren = parents?.reduce((acc, parent) => {
    acc[parent.id] = children?.filter((child) => child.parentId === parent.id);
    return acc;
  }, {});

  // Filter out parents whose children all have allowView set to false
  const visibleParents = parents?.filter((parent) => {
    const children = parentIdToChildren[parent.id];
    return children?.some((child) => menuPermissions[child.id]?.allowView);
  });

  // Filter children to only include those that are allowed to be viewed
  const visibleChildren = children?.filter(
    (child) => menuPermissions[child.id]?.allowView
  );

  // Map parent IDs to their visible children
  const parentIdToVisibleChildren = visibleParents?.reduce((acc, parent) => {
    acc[parent.id] = visibleChildren?.filter(
      (child) => child.parentId === parent.id
    );
    return acc;
  }, {});

  // Sort the parent items
  const sortedParents = visibleParents?.sort(
    (a, b) => a.sortOrder - b.sortOrder
  );

  // Nest the child items under their respective parents
  const nestedMenuItems = sortedParents?.map((parent) => ({
    ...parent,
    children: parentIdToVisibleChildren[parent.id].sort(
      (a, b) => a.sortOrder - b.sortOrder
    ),
  }));

  // nestedMenuItems.push({
  //   name: "OOH",
  //   active: true,
  //   icon: "table",
  //   children: [
  //     {
  //       name: "Inventory",
  //       url: "/ooh/inventory",
  //       exact: true,
  //       active: true,
  //     },
  //   ],
  // });

  // console.log(nestedMenuItems);

  return {
    nestedMenuItems: nestedMenuItems ?? [],
    // isLoading
  };
};
