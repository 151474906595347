import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const data = [
  {
    id: 1,
    name: "name",
    label: "Name",
    type: "text",
    placeHolder: "Name",
    required: true,
    errorMessage: "This Field is Required.",
  },
  {
    id: 2,
    name: "phone",
    label: "Phone",
    type: "number",
    placeHolder: "Phone",
    required: true,
    errorMessage: "Phone Field is Required.",
  },
  {
    id: 3,
    name: "active",
    label: "Active",
    type: "checkbox",
    placeHolder: "Active",
    required: true,
    errorMessage: "This Field is Required.",
  },
  {
    id: 4,
    label: "Select",
    name: "selectElement",
    type: "select",
    placeHolder: "Select",
    selectOptions: ["Option 1", "Option 2", "Option 3"],
    required: true,
    errorMessage: "This Field is Required.",
  },
];

const validationSchema = Yup.object().shape(
  data.reduce((schema, field) => {
    if (field.required) {
      schema[field.name] = Yup.string().required(field.errorMessage);
    }
    return schema;
  }, {})
);

const DynamicForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (formData) => {
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {data.map((item) => (
        <div key={item.id}>
          <label>{item.label}</label>
          {item.type === "text" && (
            <input
              type="text"
              placeholder={item.placeHolder}
              {...register(item.name)}
            />
          )}
          {item.type === "number" && (
            <input
              type="number"
              placeholder={item.placeHolder}
              {...register(item.name)}
            />
          )}
          {item.type === "checkbox" && (
            <input type="checkbox" {...register(item.name)} />
          )}
          {item.type === "select" && (
            <Controller
              name={item.name}
              control={control}
              render={({ field }) => (
                <select {...field}>
                  <option value="">Select</option>
                  {item.selectOptions?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}
            />
          )}
          {errors[item.name] && <p>{errors[item.name]?.message}</p>}
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  );
};

export default DynamicForm;
