import Divider from "components/common/Divider";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Bounce, toast } from "react-toastify";
import SocialAuthButtons from "./SocialAuthButtons";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { validateEmail } from "utils/Validations";
import { useAppContext } from "Main";
import "./css/captcha.css";
import { customSettings } from "utils/customConfig";
import { CONSTANT_ROUTES } from "routes";

import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const LoginForm = ({ hasLabel, layout }) => {
  const { login, state } = useAppContext();
  const navigate = useNavigate();

  const [captcha, setCaptcha] = useState();

  const [error, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // State
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });

  // Handler
  const handleSubmit = async (e) => {
    e.preventDefault();

    const validations = {};

    if (!validateEmail(formData.email)) {
      validations.email = "Email not valid!";
      setErrors(validations);
      return;
    }

    // try {
    //   if (captcha) {
    //     setLoading(true);
    //     await login(formData.email, formData.password);
    //     setLoading(false);
    //     toast.success(`Logged in as ${formData.email}`, {
    //       autoClose: customSettings.toastClose,
    //       position: customSettings.toastPosition,
    //     });
    //     navigate("/");
    //   } else {
    //     toast.error("Please Provide Captcha Verification", {
    //       autoClose: customSettings.toastClose,
    //       position: customSettings.toastPosition,
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    //   if (error.response.status === 404) {
    //     toast.error("Invalid Credentials", {
    //       autoClose: customSettings.toastClose,
    //       position: customSettings.toastPosition,
    //     });
    //   }
    // }

    try {
      setLoading(true);
      await login(formData.email, formData.password);
      setLoading(false);
      toast.success(`Logged in as ${formData.email}`, {
        autoClose: customSettings.toastClose,
        position: customSettings.toastPosition,
      });
      navigate("/");
    } catch (error) {
      console.log(error);
      if (error?.response?.status === 404) {
        toast.error("Invalid Credentials", {
          autoClose: customSettings.toastClose,
          position: customSettings.toastPosition,
        });
      }
      setLoading(false);
    }
    // toast.success(`Logged in as ${formData.email}`, {
    //   theme: "colored",
    // });
    // navigate("/");
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [showPass, setShowPass] = useState(false);
  const clickHandler = () => {
    setShowPass((prev) => !prev);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3 ">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Email address" : ""}
          value={formData.email}
          name="email"
          autoFocus
          onChange={handleFieldChange}
          type="email"
          className={`${error.email && "border border-danger"}`}
        />
        <div className="text-danger text-xs">{error.email}</div>
      </Form.Group>

      {/* <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? "Password" : ""}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type={showPass ? "text" : "password"}
        />
        <InputGroup.Text >
  
          <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} />
    
        </InputGroup.Text>
      </Form.Group> */}
      <InputGroup className="mb-3">
        <FormControl
          placeholder={!hasLabel ? "Password" : ""}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type={showPass ? "text" : "password"}
        />
        <InputGroup.Text onClick={clickHandler}>
          {" "}
          <FontAwesomeIcon icon={showPass ? faEyeSlash : faEye} />
        </InputGroup.Text>
      </InputGroup>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            className="fs-10 mb-0"
            to={CONSTANT_ROUTES.AUTHENTICATION_FORGOT_PASSWORD}
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>

      {/* <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            className="fs-10 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Forgot Password?
          </Link>
        </Col> */}
      {/* <div
        className="captcha"
        // style={{ transform: "scale(1.1)", transformOrigin: "0 0" }}
      > */}
      {customSettings.captchaOn && (
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SECRET_KEY_CAPTCHA}
          onChange={(value) => setCaptcha(value)}
          className="my-2"
        />
      )}
      {/* </div> */}

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          // disabled={!formData.email || !formData.password || !captcha}
          disabled={
            !formData.email ||
            !formData.password ||
            (customSettings.captchaOn && !captcha) ||
            loading
          }
          onClick={handleSubmit}
        >
          {loading ? "Signing In..." : "Log in"}
        </Button>
      </Form.Group>

      {/* <Divider className="mt-4">or log in with</Divider> */}

      {/* <SocialAuthButtons /> */}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool,
};

LoginForm.defaultProps = {
  layout: "simple",
  hasLabel: false,
};

export default LoginForm;
