// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { Button, Form, Modal } from "react-bootstrap";
// import { v4 as uuid } from "uuid";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import { useAppContext } from "Main";

// const AddSettingModal = ({ setIsOpenModal, isOpenModal }) => {
//   const {
//     config: { isDark },
//   } = useAppContext();

//   const [formData, setFormData] = useState({});

//   const handleClose = () => {
//     setIsOpenModal(!isOpenModal);
//   };

//   const handleChange = ({ target }) => {
//     let name = target.name;
//     let value = target.value;
//     setFormData({ ...formData, [name]: value });
//   };
//   const handleSubmit = (e) => {
//     e.preventDefault();

//     setIsOpenModal(false);
//   };

//   return (
//     <Modal show={isOpenModal} onHide={handleClose} contentClassName="border">
//       <Form onSubmit={handleSubmit}>
//         <Modal.Header
//           closeButton
//           closeVariant={isDark ? "white" : undefined}
//           className="bg-body-tertiary px-x1 border-bottom-0"
//         >
//           <Modal.Title as="h5"> Create Schedule</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="p-x1">
//           <div>
//             <label>Width</label>
//             <input type="text" />
//           </div>
//         </Modal.Body>
//         <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
//           <Button
//             variant="primary"
//             type="submit"
//             onClick={handleClose}
//             className="px-4 mx-0"
//           >
//             Save
//           </Button>
//         </Modal.Footer>
//       </Form>
//     </Modal>
//   );
// };

// export default AddSettingModal;
// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import { Button, Form, Modal } from "react-bootstrap";
// import { useAppContext } from "Main";

// const AddSettingModal = ({
//   setIsOpenModal,
//   isOpenModal,
//   applyCSS,
//   fieldKey,
// }) => {
//   const {
//     config: { isDark },
//   } = useAppContext();

//   const [formData, setFormData] = useState({});

//   useEffect(() => {
//     setFormData({});
//   }, [isOpenModal]);

//   const handleClose = () => {
//     setIsOpenModal(!isOpenModal);
//   };

//   const handleChange = ({ target }) => {
//     let name = target.name;
//     let value = target.value;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     applyCSS(fieldKey, formData);
//     setIsOpenModal(false);
//   };

//   return (
//     <Modal show={isOpenModal} onHide={handleClose} contentClassName="border">
//       <Form onSubmit={handleSubmit}>
//         <Modal.Header
//           closeButton
//           closeVariant={isDark ? "white" : undefined}
//           className="bg-body-tertiary px-x1 border-bottom-0"
//         >
//           <Modal.Title as="h5"> Apply CSS </Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="p-x1">
//           <div className="d-flex justify-content-between">
//             <label>Background Color</label>
//             <input
//               type="text"
//               name="backgroundColor"
//               value={formData.backgroundColor || ""}
//               onChange={handleChange}
//             />
//           </div>

//           <div>
//             <label>width</label>
//             <input
//               type="text"
//               name="width"
//               value={formData.width || ""}
//               onChange={handleChange}
//             />
//           </div>
//           {/* Add more CSS properties as needed */}
//         </Modal.Body>
//         <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
//           <Button variant="primary" type="submit" className="px-4 mx-0">
//             Save
//           </Button>
//         </Modal.Footer>
//       </Form>
//     </Modal>
//   );
// };

// export default AddSettingModal;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Modal } from "react-bootstrap";
import { useAppContext } from "Main";

const AddSettingModal = ({
  setIsOpenModal,
  isOpenModal,
  applyCSS,
  fieldKey,
}) => {
  const {
    config: { isDark },
  } = useAppContext();

  const [formData, setFormData] = useState({
    label: "",
    placeholder: "",
    options: "",
    styles: {},
  });

  useEffect(() => {
    setFormData({
      label: "",
      placeholder: "",
      options: "",
      styles: {},
    });
  }, [isOpenModal]);

  const handleClose = () => {
    setIsOpenModal(!isOpenModal);
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    applyCSS(fieldKey, formData);
    setIsOpenModal(false);
  };

  return (
    <Modal show={isOpenModal} onHide={handleClose} contentClassName="border">
      <Form onSubmit={handleSubmit}>
        <Modal.Header
          closeButton
          closeVariant={isDark ? "white" : undefined}
          className="bg-body-tertiary px-x1 border-bottom-0"
        >
          <Modal.Title as="h5"> Apply Settings </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-x1">
          <div className="d-flex justify-content-between">
            <label>Label:</label>
            <input
              type="text"
              name="label"
              value={formData.label}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <label>Placeholder:</label>
            <input
              type="text"
              name="placeholder"
              value={formData.placeholder}
              onChange={handleChange}
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <label>Options (Pipe | separated):</label>
            <input
              type="text"
              name="options"
              value={formData.options}
              onChange={handleChange}
              placeholder="option1|option2|option3"
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <label>Background Color:</label>
            <input
              type="text"
              name="backgroundColor"
              value={formData.styles.backgroundColor || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  styles: {
                    ...formData.styles,
                    backgroundColor: e.target.value,
                  },
                })
              }
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <label>Color:</label>
            <input
              type="text"
              name="color"
              value={formData.styles.color || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  styles: { ...formData.styles, color: e.target.value },
                })
              }
            />
          </div>
          <div className="d-flex justify-content-between mt-1">
            <label>Width:</label>
            <input
              type="text"
              name="width"
              value={formData.styles.width || ""}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  styles: { ...formData.styles, width: e.target.value },
                })
              }
            />
          </div>
          {/* Add more CSS properties as needed */}
        </Modal.Body>
        <Modal.Footer className="bg-body-tertiary px-x1 border-top-0">
          <Button variant="primary" type="submit" className="px-4 mx-0">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddSettingModal;
