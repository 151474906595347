// import React, { useState } from "react";
// import { DndContext } from "@dnd-kit/core";
// import Draggable from "./Draggable";
// import Droppable from "./Droppable";

// const DndExampleComponent = () => {
//   const initialItems = [
//     { id: "text", content: "Text Input", type: "text" },
//     { id: "number", content: "Number Input", type: "number" },
//     { id: "date", content: "Date Input", type: "date" },
//     { id: "checkbox", content: "Checkbox Input", type: "checkbox" },
//   ];

//   const [formFields, setFormFields] = useState([]);
//   const [formState, setFormState] = useState({});

//   const handleDragEnd = (event) => {
//     const { active, over } = event;
//     if (over) {
//       const draggedItem = initialItems.find((item) => item.id === active.id);
//       const newField = {
//         ...draggedItem,
//         key: `${draggedItem.id}-${Date.now()}`,
//       };
//       setFormFields((prev) => [...prev, newField]);
//       setFormState((prev) => ({ ...prev, [newField.key]: "" }));
//     }
//   };

//   const handleInputChange = (key, value) => {
//     setFormState((prev) => ({ ...prev, [key]: value }));
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     console.log("Form State:", formState);
//   };

//   return (
//     <DndContext onDragEnd={handleDragEnd}>
//       <div style={{ display: "flex", gap: "20px" }}>
//         <Droppable id="droppable">
//           <h3>Form Builder</h3>
//           <form onSubmit={handleSubmit}>
//             {formFields.length === 0
//               ? "Drop here"
//               : formFields.map((field, index) => (
//                   <div
//                     key={field.key}
//                     style={{
//                       marginBottom: "10px",
//                     }}
//                   >
//                     <div style={{ display: "flex", flexDirection: "column" }}>
//                       {field.content}:
//                       <input
//                         type={field.type}
//                         checked={
//                           field.type === "checkbox"
//                             ? formState[field.key] || false
//                             : undefined
//                         }
//                         value={
//                           field.type !== "checkbox"
//                             ? formState[field.key] || ""
//                             : undefined
//                         }
//                         onChange={(e) =>
//                           handleInputChange(
//                             field.key,
//                             field.type === "checkbox"
//                               ? e.target.checked
//                               : e.target.value
//                           )
//                         }
//                         placeholder={`Enter ${field.type}`}
//                         style={{ marginLeft: "10px" }}
//                       />
//                     </div>
//                   </div>
//                 ))}
//           </form>
//         </Droppable>
//         <div>
//           <h3>Inputs</h3>
//           {initialItems.map((item) => (
//             <Draggable key={item.id} id={item.id}>
//               {item.content}
//             </Draggable>
//           ))}
//         </div>
//       </div>
//     </DndContext>
//   );
// };

// export default DndExampleComponent;
// import React, { useState } from "react";
// import {
//   closestCenter,
//   DndContext,
//   KeyboardSensor,
//   PointerSensor,
//   useSensor,
//   useSensors,
// } from "@dnd-kit/core";
// import {
//   SortableContext,
//   rectSortingStrategy,
//   arrayMove,
//   sortableKeyboardCoordinates,
// } from "@dnd-kit/sortable";
// import Draggable from "./Draggable";
// import Droppable from "./Droppable";
// import SortableItem from "./SortableItem";
// import "./styles.css";

// const DndExampleComponent = () => {
//   const initialItems = [
//     { id: "text", content: "Text Input", type: "text" },
//     { id: "number", content: "Number Input", type: "number" },
//     { id: "date", content: "Date Input", type: "date" },
//     { id: "checkbox", content: "Checkbox Input", type: "checkbox" },
//     { id: "textArea", content: "Text Box", type: "textarea" },
//   ];

//   const [formFields, setFormFields] = useState([]);
//   const [formState, setFormState] = useState({});
//   const [cssStyles, setCssStyles] = useState({});

//   const handleDragEnd = (event) => {
//     const { active, over } = event;
//     if (!over) return;

//     const activeIndex = formFields.findIndex(
//       (field) => field.key === active.id
//     );
//     const overIndex = formFields.findIndex((field) => field.key === over.id);

//     if (activeIndex !== -1 && overIndex !== -1) {
//       setFormFields((fields) => arrayMove(fields, activeIndex, overIndex));
//     } else {
//       const draggedItem = initialItems.find((item) => item.id === active.id);
//       if (draggedItem?.id) {
//         const newField = {
//           ...draggedItem,
//           key: `${draggedItem?.id || ""}-${Date.now()}`,
//         };
//         setFormFields((prev) => [...prev, newField]);
//         setFormState((prev) => ({ ...prev, [newField.key]: "" }));
//       }
//     }
//   };

//   const handleInputChange = (key, value) => {
//     setFormState((prev) => ({ ...prev, [key]: value }));
//   };

//   // const handleSubmit = (event) => {
//   //   event.preventDefault();
//   //   console.log("Form State:", formState);
//   // };

//   const handleDelete = (key) => {
//     setFormFields((prev) => prev.filter((field) => field.key !== key));
//     setFormState((prev) => {
//       const newState = { ...prev };
//       delete newState[key];
//       return newState;
//     });
//     setCssStyles((prev) => {
//       const newStyles = { ...prev };
//       delete newStyles[key];
//       return newStyles;
//     });
//   };

//   const handleApplyCSS = (key) => {
//     const newCss = prompt(
//       'Enter CSS styles as JSON (e.g., {"backgroundColor": "red"}):'
//     );
//     try {
//       const parsedCss = JSON.parse(newCss);
//       setCssStyles((prev) => ({ ...prev, [key]: parsedCss }));
//     } catch (error) {
//       alert("Invalid CSS JSON");
//     }
//   };

//   return (
//     <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
//       <div style={{ display: "flex", gap: "20px" }}>
//         <Droppable id="droppable">
//           <h3>Form Builder</h3>
//           <SortableContext items={formFields} strategy={rectSortingStrategy}>
//             <div
//               style={{
//                 display: "grid",
//                 gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
//                 gap: "10px",
//               }}
//             >
//               {formFields.length === 0
//                 ? "Drop here"
//                 : formFields.map((field, index) => (
//                     <SortableItem
//                       key={field.key}
//                       id={field.key}
//                       onDelete={handleDelete}
//                       onApplyCSS={handleApplyCSS}
//                     >
//                       <div
//                         style={{
//                           ...cssStyles[field.key],
//                         }}
//                       >
//                         <div
//                           style={{ display: "flex", flexDirection: "column" }}
//                         >
//                           {field.type === "textarea" && (
//                             <textarea
//                               placeholder={`Enter ${field.type}`}
//                               style={{ marginLeft: "10px", resize: "both" }}
//                             />
//                           )}
//                           {/* {field.content}: */}
//                           <input
//                             type={field.type}
//                             // checked={
//                             //   field.type === "checkbox"
//                             //     ? formState[field.key] || false
//                             //     : undefined
//                             // }
//                             // value={
//                             //   field.type !== "checkbox"
//                             //     ? formState[field.key] || ""
//                             //     : undefined
//                             // }
//                             // onChange={(e) =>
//                             //   handleInputChange(
//                             //     field.key,
//                             //     field.type === "checkbox"
//                             //       ? e.target.checked
//                             //       : e.target.value
//                             //   )
//                             // }
//                             placeholder={`Enter ${field.type}`}
//                             style={{ marginLeft: "10px" }}
//                           />
//                         </div>
//                       </div>
//                     </SortableItem>
//                   ))}
//             </div>
//           </SortableContext>
//           {/* <form onSubmit={handleSubmit}></form> */}
//         </Droppable>
//         <div>
//           <h3>Inputs</h3>
//           {initialItems.map((item) => (
//             <Draggable key={item.id} id={item.id}>
//               {item.content}
//             </Draggable>
//           ))}
//         </div>
//       </div>
//     </DndContext>
//   );
// };

// export default DndExampleComponent;
import React, { useState } from "react";
import {
  closestCenter,
  DndContext,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  rectSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import Draggable from "./Draggable";
import Droppable from "./Droppable";
import SortableItem from "./SortableItem";
import "./styles.css";
import { CiText } from "react-icons/ci";
import { FaEye, FaRegCheckSquare } from "react-icons/fa";
import { BsCardText, BsCash } from "react-icons/bs";
import { IoPhonePortraitSharp } from "react-icons/io5";
import {
  MdDateRange,
  MdOutlineMailOutline,
  MdOutlineRadioButtonChecked,
} from "react-icons/md";
import { Tb123, TbNumber123 } from "react-icons/tb";
import { IoMdTime } from "react-icons/io";
import { RiCalendarEventFill } from "react-icons/ri";
import { CgSelectR } from "react-icons/cg";
import { BiSelectMultiple } from "react-icons/bi";
import AddSettingModal from "./SettingModal";
const DndExampleComponent = () => {
  const initialItems = [
    { id: "text", content: "Text", type: "text", icon: <CiText size={20} /> },
    {
      id: "password",
      content: "Password",
      type: "password",
      icon: <FaEye size={20} />,
    },
    {
      id: "currency",
      content: "Currency",
      type: "currency",
      icon: <BsCash size={20} />,
    },
    {
      id: "phone",
      content: "Phone",
      type: "phone",
      icon: <IoPhonePortraitSharp size={20} />,
    },
    {
      id: "email",
      content: "Email",
      type: "email",
      icon: <MdOutlineMailOutline size={20} />,
    },
    {
      id: "number",
      content: "Number",
      type: "Number",
      icon: <Tb123 size={20} />,
    },
    {
      id: "date",
      content: "Date",
      type: "date",
      icon: <MdDateRange size={20} />,
    },
    {
      id: "dateAndTime",
      content: "Date & Time",
      type: "datetime-local",
      icon: <RiCalendarEventFill size={20} />,
    },
    { id: "time", content: "Time", type: "time", icon: <IoMdTime size={20} /> },
    {
      id: "checkbox",
      content: "Checkbox",
      type: "checkbox",
      icon: <FaRegCheckSquare size={20} />,
    },
    {
      id: "textArea",
      content: "Rich Text",
      type: "textarea",
      icon: <BsCardText size={20} />,
    },
    {
      id: "radioBox",
      content: "Radio Box",
      type: "radio",
      icon: <MdOutlineRadioButtonChecked size={20} />,
    },
    {
      id: "select",
      content: "Select",
      type: "select",
      icon: <CgSelectR size={20} />,
    },
    {
      id: "multiSelect",
      content: "Multi Select",
      type: "select",
      icon: <BiSelectMultiple size={20} />,
    },
  ];

  const [formFields, setFormFields] = useState([]);
  const [formState, setFormState] = useState({});
  const [cssStyles, setCssStyles] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentFieldKey, setCurrentFieldKey] = useState(null);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;

    // const { active, over } = event;
    // if (!over || over.id !== "droppable") return;

    const activeIndex = formFields.findIndex(
      (field) => field.key === active.id
    );
    const overIndex = formFields.findIndex((field) => field.key === over.id);

    if (activeIndex !== -1 && overIndex !== -1) {
      setFormFields((fields) => arrayMove(fields, activeIndex, overIndex));
    } else {
      const draggedItem = initialItems.find((item) => item.id === active.id);
      if (draggedItem?.id) {
        const newField = {
          ...draggedItem,
          key: `${draggedItem?.id || ""}-${Date.now()}`,
        };
        setFormFields((prev) => [...prev, newField]);
        setFormState((prev) => ({ ...prev, [newField.key]: "" }));
      }
    }
  };

  const handleInputChange = (key, value) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const handleDelete = (key) => {
    setFormFields((prev) => prev.filter((field) => field.key !== key));
    setFormState((prev) => {
      const newState = { ...prev };
      delete newState[key];
      return newState;
    });
    setCssStyles((prev) => {
      const newStyles = { ...prev };
      delete newStyles[key];
      return newStyles;
    });
  };

  // const handleApplyCSS = (key) => {
  //   const newCss = prompt(
  //     'Enter CSS styles as JSON (e.g., {"backgroundColor": "red"}):'
  //   );
  //   try {
  //     const parsedCss = JSON.parse(newCss);
  //     setCssStyles((prev) => ({ ...prev, [key]: parsedCss }));
  //   } catch (error) {
  //     alert("Invalid CSS JSON");
  //   }
  // };

  const handleApplyCSS = (key) => {
    setCurrentFieldKey(key);
    setIsOpenModal(true);
  };

  const applyCSS = (key, styles) => {
    setCssStyles((prev) => ({ ...prev, [key]: styles }));
  };

  return (
    <>
      <DndContext onDragEnd={handleDragEnd}>
        <div style={{ display: "flex", gap: "20px" }}>
          <Droppable id="droppable">
            <h3>Form Builder</h3>
            <SortableContext items={formFields} strategy={rectSortingStrategy}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                  gridAutoRows: "min-content",
                  gap: "10px",
                  width: "100%",
                }}
              >
                {formFields.length === 0
                  ? "Drop here"
                  : formFields.map((field) => (
                      <SortableItem
                        key={field.key}
                        id={field.key}
                        onDelete={handleDelete}
                        onApplyCSS={handleApplyCSS}
                      >
                        <div
                          style={{
                            ...cssStyles[field.key]?.styles,
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {cssStyles[field.key]?.label || <div>&nbsp;</div>}
                            {field.type === "textarea" ? (
                              <textarea
                                value={formState[field.key] || ""}
                                onChange={(e) =>
                                  handleInputChange(field.key, e.target.value)
                                }
                                placeholder={
                                  cssStyles[field.key]?.placeholder ||
                                  `Enter ${field.type}`
                                }
                                style={{
                                  marginLeft: "10px",
                                  resize: "both",
                                }}
                              />
                            ) : field.type === "select" ? (
                              <select
                                value={formState[field.key] || ""}
                                onChange={(e) =>
                                  handleInputChange(field.key, e.target.value)
                                }
                                style={{
                                  marginLeft: "10px",
                                }}
                              >
                                <option value="">Select an option</option>
                                {(cssStyles[field.key]?.options || "")
                                  .split("|")
                                  .map((option, index) => (
                                    <option key={index} value={option.trim()}>
                                      {option.trim()}
                                    </option>
                                  ))}
                              </select>
                            ) : (
                              <input
                                type={field.type}
                                value={formState[field.key] || ""}
                                onChange={(e) =>
                                  handleInputChange(field.key, e.target.value)
                                }
                                placeholder={
                                  cssStyles[field.key]?.placeholder ||
                                  `Enter ${field.type}`
                                }
                                style={{
                                  marginLeft: "10px",
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </SortableItem>
                    ))}
              </div>
            </SortableContext>
          </Droppable>
          <div>
            <h3>Inputs</h3>
            {initialItems.map((item) => (
              <Draggable key={item.id} id={item.id}>
                <div className="px-2 gap-2 d-flex align-items-center">
                  {item.icon}
                  {item.content}
                </div>
              </Draggable>
            ))}
          </div>
        </div>
      </DndContext>
      <AddSettingModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        applyCSS={applyCSS}
        fieldKey={currentFieldKey}
      />
    </>
  );
};

export default DndExampleComponent;
