import { yupResolver } from "@hookform/resolvers/yup";
import { LibraryAPI } from "api";
import BreadCrumb from "components/breadcrumb/BreadCrumb";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";

import AddAgencyHeader from "./AddAgencyHeader";
import AddAgencyBasicInformation from "./AddAgencyBasicInformation";
import { customSettings } from "utils/customConfig";
import PageTitle from "utils/PageTitle";

const schema = yup
  .object({
    type: yup.string().required("This field is required."),
    name: yup.string().required("This field is required."),
    logo: yup.string(),
    phone: yup.string().required("This field is required."),
    email: yup.string().required("This field is required."),
    active: yup.boolean(),
    // designation: yup.string().required("This field is required."),
    // gender: yup.string().required("This field is required."),
    // dob: yup.string().required("This field is required."),
    // address: yup.string().required("This field is required."),
    // country: yup.string().required("This field is required."),
    // state: yup.string().required("This field is required."),
    // city: yup.string().required("This field is required."),
    // zip: yup.string().required("This field is required."),
  })
  .required();

const AddAgency = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = { ...data };

    {
      myData.id = location?.state ? location?.state?.id : 0;
      myData.library = 27;
      myData.logo = "";
      myData.level = "";
      myData.strength = "";
    }

    try {
      await LibraryAPI.post(myData);
      toast.success("Posted", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      reset(location.state);
    }
  }, []);

  return (
    <>
      <PageTitle name="Agencies" />
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <AddAgencyHeader />
            </Col>
            <Col lg={12}>
              <AddAgencyBasicInformation />
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
            {/* <Col xs={12}>
              <AddClientFooter />
            </Col> */}
          </Row>
        </Form>
      </FormProvider>
      {/* <PartyContactsTable /> */}
    </>
  );
};

export default AddAgency;
