import React from "react";
import { Card, Col, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../styles.css";

const SpaceTypeHeader = () => {
  const navigate = useNavigate();
  return (
    <Card className="sticky-header">
      <Card.Body>
        <Row className="flex-between-center ">
          <Col md>
            <h5 className="mb-2 mb-md-0">Space Type</h5>
          </Col>
          <Col xs="auto">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Ok
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SpaceTypeHeader;
