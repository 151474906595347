import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import InventoryBasicInformation from "./InventoryBasicInformation";

import BreadCrumb from "components/breadcrumb/BreadCrumb";
import { useLocation, useNavigate } from "react-router-dom";
import CostingInformation from "./CostingInformation";
import InventoryHeader from "./InventoryHeader";
import { customSettings } from "utils/customConfig";
import PageTitle from "utils/PageTitle";
import { InventoryAPI } from "api";
import { toast } from "react-toastify";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

const schema = yup
  .object({
    adSpace: yup
      .object({
        type: yup.string().required("This field is required."),
        name: yup.string().required("This field is required."),
        description: yup.string().required("This field is required."),
        category: yup.string().required("This field is required."),
        authority: yup.string().required("This field is required."),
        address: yup.string().required("This field is required."),
        size: yup.string().required("This field is required."),
        side: yup.string().required("This field is required."),
        ownershipType: yup.string().required("This field is required."),
        status: yup.string().required("This field is required."),
        acquiredOn: yup.string().required("This field is required."),
        availableOn: yup.string().required("This field is required."),
      })
      .required("This field is required."),
    adSpaceLocation: yup
      .object({
        region: yup.string().required("This field is required."),
        city: yup.string().required("This field is required."),
        marketArea: yup.string().required("This field is required."),
        latitude: yup.string().required("This field is required."),
        longitude: yup.string().required("This field is required."),
        mapUrl: yup.string().required("This field is required."),
      })
      .required("This field is required."),
    adFeatures: yup
      .object({
        visibility: yup.string().required("This field is required."),
        lights: yup.string().required("This field is required."),
        strata: yup.string().required("This field is required."),
        class: yup.string().required("This field is required."),
        score: yup.string().required("This field is required."),
        trafficSpeed: yup.string().required("This field is required."),
        trafficCount: yup.string().required("This field is required."),
        height: yup.string().required("This field is required."),
        angle: yup.string().required("This field is required."),
        distance: yup.string().required("This field is required."),
      })
      .required("This field is required."),
    // pictures: yup.array().of(yup.string()),
    // videos: yup.array().of(yup.string()),
    designCharges: yup
      .object({
        type: yup.string().required("This field is required."),
        vendor: yup.string().required("This field is required."),
        price: yup.number().required("This field is required."),
        attribute1: yup.string(),
        attribute2: yup.string(),
      })
      .required("This field is required."),
    printingCharges: yup
      .object({
        type: yup.string().required("This field is required."),
        vendor: yup.string().required("This field is required."),
        price: yup.number().required("This field is required."),
        attribute1: yup.string(),
        attribute2: yup.string(),
      })
      .required("This field is required."),
    installationCharges: yup
      .object({
        type: yup.string().required("This field is required."),
        vendor: yup.string().required("This field is required."),
        price: yup.number().required("This field is required."),
        attribute1: yup.string(),
        attribute2: yup.string(),
      })
      .required("This field is required."),
    electricityCharges: yup
      .object({
        type: yup.string(),
        vendor: yup.string(),
        price: yup.number(),
        attribute1: yup.string(),
        attribute2: yup.string(),
      })
      .required("This field is required."),
  })
  .required();

const AddInventory = () => {
  const [key, setKey] = useState("basicInfo");
  const navigate = useNavigate();
  const location = useLocation();

  const submittedValues = {};
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });
  const { handleSubmit, reset, setValue } = methods;

  const onSubmit = async (data) => {
    let myData = {
      ...data,
      id: location?.state ? location?.state?.id : 0,
      pictures: [],
      videos: [],
      adSpace: {
        ...data.adSpace,
        acquiredOn: new Date(data.adSpace.acquiredOn).toISOString(),
        availableOn: new Date(data.adSpace.availableOn).toISOString(),
      },
      designCharges: {
        ...data.designCharges,
        attribute1: "",
        attribute2: "",
      },
      electricityCharges: {
        ...data.electricityCharges,
        type: "",
        attribute1: "",
        attribute2: "",
      },
      installationCharges: {
        ...data.installationCharges,
        attribute2: "",
      },
      printingCharges: {
        ...data.printingCharges,
        attribute2: "",
      },
    };

    console.log(myData);

    try {
      await InventoryAPI.post(myData);
      toast.success("Posted", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });

      // ------- Get all object keys form data and set empty values to reset ------------
      const keys = Object.keys(data);
      for (const key of keys) {
        submittedValues[key] = "";
      }
      reset({ ...submittedValues });
      navigate(-1);
    } catch (error) {
      toast.error("Something Wrong", {
        position: customSettings.toastPosition,
        autoClose: customSettings.toastClose,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    if (location.state) {
      const {
        type,
        name,
        description,
        category,
        authority,
        address,
        size,
        side,
        ownershipType,
        status,
        acquiredOn,
        availableOn,
        region,
        city,
        marketArea,
        latitude,
        longitude,
        mapUrl,
        visibility,
        lights,
        strata,
        class: adClass,
        score,
        trafficSpeed,
        trafficCount,
        height,
        angle,
        distance,
        designType,
        designVendor,
        designPrice,
        printingMaterialType,
        printingVendor,
        printingSize,
        printingPrice,
        installationWorkType,
        installationVendor,
        installationMaterialType,
        installationPrice,
        electricityVendor,
        electricityPrice,
      } = location.state;

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toISOString().slice(0, 10); // Extract the "yyyy-MM-dd" part
      };

      const formattedAcquiredOn = acquiredOn ? formatDate(acquiredOn) : null;
      const formattedAvailableOn = availableOn ? formatDate(availableOn) : null;

      // Map the flat object to your schema structure
      const mappedValues = {
        adSpace: {
          type,
          name,
          description,
          category,
          authority,
          address,
          size,
          side,
          ownershipType,
          status,
          acquiredOn: formattedAcquiredOn, // Use "yyyy-MM-dd" format
          availableOn: formattedAvailableOn, // Use "yyyy-MM-dd" format
        },
        adSpaceLocation: {
          region,
          city,
          marketArea,
          latitude,
          longitude,
          mapUrl,
        },
        adFeatures: {
          visibility,
          lights,
          strata,
          class: adClass,
          score,
          trafficSpeed,
          trafficCount,
          height,
          angle,
          distance,
        },
        designCharges: {
          type: designType,
          vendor: designVendor,
          price: designPrice,
          attribute1: "", // Assuming this is empty
          attribute2: "", // Assuming this is empty
        },
        printingCharges: {
          type: printingMaterialType,
          vendor: printingVendor,
          price: printingPrice,
          attribute1: printingSize, // Assuming this is empty
          attribute2: "", // Assuming this is empty
        },
        installationCharges: {
          type: installationMaterialType,
          vendor: installationVendor,
          price: installationPrice,
          attribute1: installationWorkType, // Assuming this is the correct mapping
          attribute2: "", // Assuming this is empty
        },
        electricityCharges: {
          type: "", // Assuming this is empty
          vendor: electricityVendor,
          price: electricityPrice,
          attribute1: "", // Assuming this is empty
          attribute2: "", // Assuming this is empty
        },
      };

      reset(mappedValues);
    }
  }, [location.state, reset]);

  return (
    <>
      <PageTitle name="Inventory" />
      <div className="py-2">
        <BreadCrumb />
      </div>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <InventoryHeader />
            </Col>
            <Col lg={12}>
              <Card className="mb-3 py-2">
                {/* <Card.Header>
                  <h5 className="fs-9 mb-0 text-nowrap py-2 py-xl-0">
                    Inventory
                  </h5>
                </Card.Header> */}
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                >
                  <Tab eventKey="basicInfo" title="Basic Info" className="p-3 ">
                    <InventoryBasicInformation />
                  </Tab>
                  <Tab eventKey="costing" title="Costing" className=" p-3">
                    <CostingInformation />
                  </Tab>
                </Tabs>
                {/* <hr />
                <Row className="gx-2 gy-3 my-1 px-3">
                  <Col className="d-flex justify-content-end">
                    <Button
                      variant="link"
                      className="text-secondary fw-medium p-0 me-3"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </Button>
                    <Button variant="primary" type="submit">
                      Ok
                    </Button>
                  </Col>
                </Row> */}
              </Card>

              {/* <CategoryBasicInformation /> */}
              {/* <ProductUpload /> */}
              {/* <ProductDetails /> */}
              {/* <ProductSpecifications /> */}
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </>
  );
};

export default AddInventory;
