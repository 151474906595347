import { LibraryAPI } from "api";
import { useFetchAngles } from "hooks/useFetchAngles";
import { useFetchAuthorities } from "hooks/useFetchAuthorities";
import { useFetchCategories } from "hooks/useFetchCategories";
import { useFetchCities } from "hooks/useFetchCities";
import { useFetchDistances } from "hooks/useFetchDistances";
import { useFetchMarkets } from "hooks/useFetchMarkets";
import { useFetchRegions } from "hooks/useFetchRegions";
import { useFetchSpaceAddresses } from "hooks/useFetchSpaceAddresses";
import { useFetchSpaceClasses } from "hooks/useFetchSpaceClasses";
import { useFetchSpaceScores } from "hooks/useFetchSpaceScores";
import { useFetchSpaceSize } from "hooks/useFetchSpaceSize";
import { useFetchSpaceTypes } from "hooks/useFetchSpaceTypes";
import { useFetchStatuses } from "hooks/useFetchStatuses";
import { useFetchVisibilities } from "hooks/useFetchVisibilities";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const InventoryBasicInformation = () => {
  const {
    register,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const formId = useQuery().get("id");

  let regionValue = watch("adSpaceLocation.region");
  let cityValue = watch("adSpaceLocation.city");

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      // const filePath = URL.createObjectURL(file); // This is a temporary URL, for preview or similar purposes
      const filePath = file.name; // This is a temporary URL, for preview or similar purposes
      setValue(fieldName, filePath); // Store the file path in the form state
    }
  };

  const {
    data: spaceTypes,
    isLoading: isLoadingSpaceTypes,
    isFetching: isFetchingSpaceTypes,
  } = useFetchSpaceTypes();
  const {
    data: spaceAddresses,
    isLoading: isLoadingSpaceAddresses,
    isFetching: isFetchingSpaceAddresses,
  } = useFetchSpaceAddresses();
  const {
    data: categories,
    isLoading: isLoadingCategories,
    isFetching: isFetchingCategories,
  } = useFetchCategories();
  const {
    data: statuses,
    isLoading: isLoadingStatuses,
    isFetching: isFetchingStatuses,
  } = useFetchStatuses();
  const {
    data: regions,
    isLoading: isLoadingRegions,
    isFetching: isFetchingRegions,
  } = useFetchRegions();
  const {
    data: marketAreas,
    isLoading: isLoadingMarkets,
    isFetching: isFetchingMarkets,
  } = useFetchMarkets();
  const {
    data: visibilities,
    isLoading: isLoadingVisibilities,
    isFetching: isFetchingVisibilities,
  } = useFetchVisibilities();
  const {
    data: spaceClasses,
    isLoading: isLoadingSpaceClasses,
    isFetching: isFetchingSpaceClasses,
  } = useFetchSpaceClasses();
  const {
    data: spaceScores,
    isLoading: isLoadingSpaceScores,
    isFetching: isFetchingSpaceScores,
  } = useFetchSpaceScores();
  const {
    data: distances,
    isLoading: isLoadingDistances,
    isFetching: isFetchingDistances,
  } = useFetchDistances();
  const {
    data: angles,
    isLoading: isLoadingAngles,
    isFetching: isFetchingAngles,
  } = useFetchAngles();
  const {
    data: cities,
    isLoading: isLoadingCities,
    isFetching: isFetchingCities,
  } = useFetchCities();
  const {
    data: authorities,
    isLoading: isLoadingAuthorities,
    isFetching: isFetchingAuthorities,
  } = useFetchAuthorities();
  const {
    data: spaceSize,
    isLoading: isLoadingSpaceSize,
    isFetching: isFetchingSpaceSize,
  } = useFetchSpaceSize();

  if (
    isLoadingSpaceTypes ||
    isLoadingSpaceAddresses ||
    isLoadingCategories ||
    isLoadingStatuses ||
    isLoadingRegions ||
    isLoadingMarkets ||
    isLoadingVisibilities ||
    isLoadingSpaceClasses ||
    isLoadingSpaceScores ||
    isLoadingDistances ||
    isLoadingAngles ||
    isLoadingCities ||
    isLoadingAuthorities ||
    isLoadingSpaceSize
  ) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <>
      <Card.Header as="h6" className="bg-body-tertiary mb-2">
        Basic information
      </Card.Header>
      <Row className="gx-2 gy-3">
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Type">
              <Form.Select
                aria-label="adSpace.type"
                autoFocus
                isInvalid={!!errors?.adSpace?.type}
                {...register("adSpace.type")}
              >
                <option value="">Select</option>
                {spaceTypes?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.type?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Space Id"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adSpace?.name}
                {...register("adSpace.name")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.name?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel
              controlId="floatingInput"
              label="Description"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adSpace?.description}
                {...register("adSpace.description")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.description?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Category">
              <Form.Select
                aria-label="category"
                isInvalid={!!errors?.adSpace?.category}
                {...register("adSpace.category")}
              >
                <option value="">Select</option>
                {categories?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.category?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Authority">
              <Form.Select
                aria-label="authority"
                isInvalid={!!errors?.adSpace?.authority}
                {...register("adSpace.authority")}
              >
                <option value="">Select</option>
                {authorities?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.authority?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Address">
              <Form.Select
                aria-label="spaceAddress"
                isInvalid={!!errors?.adSpace?.address}
                {...register("adSpace.address")}
              >
                <option value="">Select</option>
                {spaceAddresses?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.address?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Size">
              <Form.Select
                aria-label="spaceSize"
                isInvalid={!!errors?.adSpace?.size}
                {...register("adSpace.size")}
              >
                <option value="">Select</option>
                {spaceSize?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {Number(d.phone) * Number(d.email)}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.size?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Side">
              <Form.Select
                aria-label="side"
                isInvalid={!!errors?.adSpace?.side}
                {...register("adSpace.side")}
              >
                <option value="">Select</option>
                <option value="A">A</option>
                <option value="B">B</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.side?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel
              controlId="floatingSelectGrid"
              label="Ownership Type"
            >
              <Form.Select
                aria-label="ownershipType"
                isInvalid={!!errors?.adSpace?.ownershipType}
                {...register("adSpace.ownershipType")}
              >
                <option value="">Select</option>
                <option value="own">Own</option>
                <option value="rental">Rental</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.ownershipType?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Status">
              <Form.Select
                aria-label="status"
                isInvalid={!!errors?.adSpace?.status}
                {...register("adSpace.status")}
              >
                <option value="">Select</option>
                {statuses?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.status?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Acquired Date"
              className=""
            >
              <Form.Control
                type="date"
                placeholder=""
                isInvalid={!!errors?.adSpace?.acquiredOn}
                {...register("adSpace.acquiredOn")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.acquiredOn?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Available Dates"
              className=""
            >
              <Form.Control
                type="date"
                placeholder=""
                isInvalid={!!errors?.adSpace?.availableOn}
                {...register("adSpace.availableOn")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adSpace?.availableOn?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Card.Header as="h6" className="bg-body-tertiary mb-2">
          Space Location
        </Card.Header>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Region">
              <Form.Select
                aria-label="region"
                isInvalid={!!errors?.adSpaceLocation?.region}
                {...register("adSpaceLocation.region")}
              >
                <option value="">Select</option>
                {regions?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpaceLocation?.region?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="City">
              <Form.Select
                aria-label="city"
                isInvalid={!!errors?.adSpaceLocation?.city}
                disabled={!regionValue}
                {...register("adSpaceLocation.city")}
              >
                <option value="">Select</option>
                {cities?.data.data
                  .filter((r) => r.parentId1 === Number(regionValue))
                  .map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpaceLocation?.city?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Market / Area">
              <Form.Select
                aria-label="area"
                isInvalid={!!errors?.adSpaceLocation?.marketArea}
                disabled={!cityValue}
                {...register("adSpaceLocation.marketArea")}
              >
                <option value="">Select</option>
                {marketAreas?.data.data
                  .filter((r) => r.parentId2 === Number(cityValue))
                  .map((d) => (
                    <option key={d.id} value={d.id}>
                      {d.name}
                    </option>
                  ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adSpaceLocation?.marketArea?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Latitude"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adSpaceLocation?.latitude}
                {...register("adSpaceLocation.latitude")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adSpaceLocation?.latitude?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Longitude"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adSpaceLocation?.longitude}
                {...register("adSpaceLocation.longitude")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adSpaceLocation?.longitude?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Google Map"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adSpaceLocation?.mapUrl}
                {...register("adSpaceLocation.mapUrl")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adSpaceLocation?.mapUrl?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Card.Header as="h6" className="bg-body-tertiary mb-2">
          Features
        </Card.Header>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Visibility">
              <Form.Select
                aria-label="visibility"
                isInvalid={!!errors?.adFeatures?.visibility}
                {...register("adFeatures.visibility")}
              >
                <option value="">Select</option>
                {visibilities?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.visibility?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Lights"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adFeatures?.lights}
                {...register("adFeatures.lights")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.lights?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Strata"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adFeatures?.strata}
                {...register("adFeatures.strata")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.strata?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Class">
              <Form.Select
                aria-label="spaceClass"
                isInvalid={!!errors?.adFeatures?.class}
                {...register("adFeatures.class")}
              >
                <option value="">Select</option>
                {spaceClasses?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.class?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Space Score">
              <Form.Select
                aria-label="spaceScore"
                isInvalid={!!errors?.adFeatures?.score}
                {...register("adFeatures.score")}
              >
                <option value="">Select</option>
                {spaceScores?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.score?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Traffic Speed"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adFeatures?.trafficSpeed}
                {...register("adFeatures.trafficSpeed")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.trafficSpeed?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Traffic Count"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adFeatures?.trafficCount}
                {...register("adFeatures.trafficCount")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.trafficCount?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Height"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.adFeatures?.height}
                {...register("adFeatures.height")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.height?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Angle">
              <Form.Select
                aria-label="angle"
                isInvalid={!!errors?.adFeatures?.angle}
                {...register("adFeatures.angle")}
              >
                <option value="">Select</option>
                {angles?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.angle?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Distance">
              <Form.Select
                aria-label="distance"
                isInvalid={!!errors?.adFeatures?.distance}
                {...register("adFeatures.distance")}
              >
                <option value="">Select</option>
                {distances?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.adFeatures?.distance?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="12">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Pictures</Form.Label>
            <Form.Control
              type="file"
              size="lg"
              isInvalid={!!errors.pictures}
              {...register("pictures")}
              // onChange={(e) => handleFileChange(e, "pictures")}
            />
            <Form.Control.Feedback type="invalid">
              {errors.pictures?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md="12">
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Videos</Form.Label>
            <Form.Control
              type="file"
              size="lg"
              isInvalid={!!errors.videos}
              {...register("videos")}
              // onChange={(e) => handleFileChange(e, "videos")} // Handle file change
            />
            <Form.Control.Feedback type="invalid">
              {errors.videos?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {/* <Row className="gx-2 gy-3 my-1">
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className="text-secondary fw-medium p-0 me-3"
            type="button"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button variant="primary" onClick={() => setKey("costing")}>
            Next
          </Button>
        </Col>
      </Row> */}
    </>
  );
};

export default InventoryBasicInformation;
