import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AuthSimpleLayout from "../layouts/AuthSimpleLayout";
import ErrorLayout from "../layouts/ErrorLayout";
import MainLayout from "../layouts/MainLayout";

import Error404 from "components/errors/Error404";
import Error500 from "components/errors/Error500";

import SimpleForgetPassword from "components/authentication/simple/ForgetPassword";
import SimpleLogin from "components/authentication/simple/Login";
import SimplePasswordReset from "components/authentication/simple/PasswordReset";

import Dashboard from "components/dashboards/default";

import AuthoritiesRateListTable from "components/libraries-menu/AuthoritiesRateList/AuthoritiesRateListTable";
import AddAuthoritiesRateList from "components/libraries-menu/AuthoritiesRateList/add-authorities-rate-list/AddAuthoritiesRateList";
import CategoriesTable from "components/libraries-menu/Categories/CategoriesTable";
import AddCategory from "components/libraries-menu/Categories/add-category/AddCategory";
import ChannelTable from "components/libraries-menu/Channels/ChannelTable";
import AddChannel from "components/libraries-menu/Channels/add-channel/AddChannel";
import CitiesTable from "components/libraries-menu/Cities/CitiesTable";
import AddCity from "components/libraries-menu/Cities/add-city/AddCity";
import ClassesTable from "components/libraries-menu/Classes/ClassesTable";
import AddClass from "components/libraries-menu/Classes/add-classes/AddClasses";
import ContactsTable from "components/libraries-menu/Contacts/ContactsTable";
import AddContact from "components/libraries-menu/Contacts/add-contact/AddContact";
import HorecaTable from "components/libraries-menu/HoReCa/HorecaTable";
import AddHoreca from "components/libraries-menu/HoReCa/add-horeca/AddHoreca";
import LocationsAddressesTable from "components/libraries-menu/Locations-Addresses/LocationsAddressesTable";
import AddLocationAddress from "components/libraries-menu/Locations-Addresses/add-location-addresses/AddLocationAddress";
import LocationsTable from "components/libraries-menu/Locations/LocationsTable";
import AddLocation from "components/libraries-menu/Locations/add-location/AddLocation";
import RegionsTable from "components/libraries-menu/Regions/RegionsTable";
import AddRegion from "components/libraries-menu/Regions/add-region/AddRegion";
import SchoolsTable from "components/libraries-menu/Schools/SchoolsTable";
import AddSchool from "components/libraries-menu/Schools/add-school/AddSchool";
import SizesTable from "components/libraries-menu/Sizes/SizesTable";
import AddSize from "components/libraries-menu/Sizes/add-size/AddSize";
import UnitOfMeasurementTable from "components/libraries-menu/UnitsOfMeasurements/UnitOfMeasurementTable";
import AddUOM from "components/libraries-menu/UnitsOfMeasurements/add-uom/AddUOM";
import PlacesTable from "components/libraries-menu/places/PlacesTable";
import AddPlace from "components/libraries-menu/places/add-place/AddPlace";

import InventoryTable from "components/OOH/Inventory/InventoryTable";
import AddInventory from "components/OOH/Inventory/add-inventory/AddInventory";
import GroupsTable from "components/administration-menu/Groups/GroupsTable";
import AddGroup from "components/administration-menu/Groups/add-group/AddGroup";
import RolesAndPermissionsTable from "components/administration-menu/RolesAndPermissions/RolesAndPermissionsTable";
import AddAssignment from "components/administration-menu/RolesAndPermissions/add-assignment/AddAssignment";
import AddRoleAndPermission from "components/administration-menu/RolesAndPermissions/add-roleAndPermission/AddRoleAndPermission";
import UsersTable from "components/administration-menu/Users/UsersTable";
import AddUser from "components/administration-menu/Users/add-user/AddUser";
import Calendar from "components/calendar/Calendar";
import AgGridInfiniteScroll from "components/examples/AgGridInfiniteScroll/AgGridInfiniteScroll";
import DndExampleComponent from "components/examples/DnDExample/DndExampleComponent";
import DynamicForm from "components/examples/DynamicForm/DynamicForm";
import GoogleMapExample from "components/examples/GoogleMapExample/GoogleMap";
import TreeViewFalconExample from "components/examples/TreeViewFalconExample/TreeViewFaconExample";
import AttendancesTable from "components/human-resource-menu/Attendence/AttendancesTable";
import AddAttendance from "components/human-resource-menu/Attendence/add-Attendence/AddAttendance";
import PromotersOrHRTable from "components/human-resource-menu/Promoter/PromotersOrHRTable";
import AddPromoter from "components/human-resource-menu/Promoter/add-Promoter/AddPromoter";
import ProductsTable from "components/inventories-menu/Products/ProductsTable";
import AddProduct from "components/inventories-menu/Products/add-product/AddProduct";
import AgenciesTable from "components/libraries-menu/Agencies/AgenciesTable";
import AddAgency from "components/libraries-menu/Agencies/add-agency/AddAgency";
import AddAgencyContact from "components/libraries-menu/Agencies/agencies-contacts/add-agency-contact/AddAgencyContact";
import AuthoritiesTable from "components/libraries-menu/Authorities/AuthoritiesTable";
import AddAuthority from "components/libraries-menu/Authorities/add-authority/AddAuthority";
import AddAuthorityContact from "components/libraries-menu/Authorities/authority-contacts/add-authority-contact/AddAuthorityContact";
import BrandGroupsTable from "components/libraries-menu/BrandGroups/BrandGroupsTable";
import AddBrandGroups from "components/libraries-menu/BrandGroups/add-brandGroups/AddBrandGroups";
import BransTable from "components/libraries-menu/Brans/BransTable";
import AddBrans from "components/libraries-menu/Brans/add-brans/AddBrans";
import BusinessUnitsTable from "components/libraries-menu/BusinessUnits/BusinessUnitsTable";
import AddBusinessUnit from "components/libraries-menu/BusinessUnits/add-businessUnit/AddBusinessUnit";
import ClientsTable from "components/libraries-menu/Clients/ClientsTable";
import AddClient from "components/libraries-menu/Clients/add-client/AddClient";
import AddClientContact from "components/libraries-menu/Clients/clients-contacts/add-client-contact/AddClientContact";
import DesignTypesTable from "components/libraries-menu/DesignTypes/DesignTypesTable";
import AddDesignType from "components/libraries-menu/DesignTypes/add-design/AddDesignType";
import DistancesTable from "components/libraries-menu/Distances/DistancesTable";
import AddDistance from "components/libraries-menu/Distances/add-distance/AddDistance";
import DriversTable from "components/libraries-menu/Drivers/DriversTable";
import AddDriver from "components/libraries-menu/Drivers/add-driver/AddDriver";
import MarketsTable from "components/libraries-menu/Markets/MarketsTable";
import AddMarket from "components/libraries-menu/Markets/add-market/AddMarket";
import MediumMaterialTypesTable from "components/libraries-menu/MediumMaterialTypes/MediumMaterialTypesTable";
import AddMediumMaterialType from "components/libraries-menu/MediumMaterialTypes/add-mmt/AddMediumMaterialType";
import MediumsTableSecond from "components/libraries-menu/Mediums-Second/MediumsTableSecond";
import AddMediumSecond from "components/libraries-menu/Mediums-Second/add-medium-second/AddMediumSecond";
import MediumsTable from "components/libraries-menu/Mediums/MediumsTable";
import AddMedium from "components/libraries-menu/Mediums/add-medium/AddMedium";
import PartnersTable from "components/libraries-menu/Partners/PartnersTable";
import AddPartner from "components/libraries-menu/Partners/add-partner/AddPartner";
import AddPartnerContact from "components/libraries-menu/Partners/partners-contacts/add-partner-contact/AddPartnerContact";
import SitesTable from "components/libraries-menu/Sites/SitesTable";
import AddSite from "components/libraries-menu/Sites/add-site/AddSite";
import SpaceAddressesTable from "components/libraries-menu/SpaceAddresses/SpaceAddressesTable";
import AddSpaceAddress from "components/libraries-menu/SpaceAddresses/add-space-address/AddSpaceAddress";
import SpaceClassesTable from "components/libraries-menu/SpaceClasses/SpaceClassesTable";
import AddSpaceClass from "components/libraries-menu/SpaceClasses/add-space-class/AddSpaceClass";
import SpaceScoreTable from "components/libraries-menu/SpaceScore/SpaceScoreTable";
import AddSpaceScore from "components/libraries-menu/SpaceScore/add-space-score/AddSpaceScore";
import SpaceTypesTable from "components/libraries-menu/SpaceTypes/SpaceTypesTable";
import AddSpaceType from "components/libraries-menu/SpaceTypes/add-space-type/AddSpaceType";
import StatusesTable from "components/libraries-menu/Statues/StatusesTable";
import AddStatus from "components/libraries-menu/Statues/add-status/AddStatus";
import StoresTable from "components/libraries-menu/Store/StoresTable";
import AddStore from "components/libraries-menu/Store/add-store/AddStore";
import VehiclesTable from "components/libraries-menu/Vehicles/VehiclesTable";
import AddVehicle from "components/libraries-menu/Vehicles/add-vehicle/AddVehicle";
import VendorsTable from "components/libraries-menu/Vendors/VendorsTable";
import AddVendor from "components/libraries-menu/Vendors/add-vendor/AddVendor";
import AddVendorContact from "components/libraries-menu/Vendors/vendors-contacts/add-vendor-contact/AddVendorContact";
import VenueTable from "components/libraries-menu/Venues/VenueTable";
import AddVenue from "components/libraries-menu/Venues/add-venue/AddVenue";
import VisibilityTable from "components/libraries-menu/Visibility/VisibilityTable";
import AddVisibility from "components/libraries-menu/Visibility/add-visibility/AddVisibility";
import VisibilityAnglesTable from "components/libraries-menu/VisibilityAngles/VisibilityAnglesTable";
import AddVisibilityAngle from "components/libraries-menu/VisibilityAngles/add-visibility-angle/AddVisibilityAngle";
import WorkTypesTable from "components/libraries-menu/WorkTypes/WorkTypesTable";
import AddWorkType from "components/libraries-menu/WorkTypes/add-work-type/AddWorkType";
import Profile from "components/pages/user/profile/Profile";
import Timeline from "components/timeline/Timeline";
import AuthGuard from "./Gaurd/AuthGaurd";

export const CONSTANT_ROUTES = {
  AUTHENTICATION_LOGIN: "/login",
  AUTHENTICATION_REGISTER: "/register",
  AUTHENTICATION_RESET_PASSWORD: "/reset-password",
  AUTHENTICATION_FORGOT_PASSWORD: "/forgot-password",
  USERPROFILE: "/userProfile",
  HOME: "/",
  CATEGORIES_TABLE: "",
  CATEGORY_ADD: "",
  MARKETS_TABLE: "/libraries/markets",
  ADD_NEW_MARKETS: "/libraries/markets/add-new",
  SPACE_ADDRESSES_TABLE: "/libraries/space-addresses",
  ADD_NEW_SPACE_ADDRESS: "/libraries/space-addresses/add-new",
  STATUES_TABLE: "/libraries/statuses",
  ADD_NEW_STATUS: "/libraries/statuses/add-new",
  MEDIUMS_TABLE: "/libraries/mediums",
  ADD_NEW_MEDIUM: "/libraries/mediums/add-new",
  VISIBILITY_TABLE: "/libraries/visibility",
  ADD_NEW_VISIBILITY: "/libraries/visibility/add-new",
  SPACE_CLASSES_TABLE: "/libraries/space-classes",
  ADD_NEW_SPACE_CLASS: "/libraries/space-classes/add-new",
  SPACE_SCORE_TABLE: "/libraries/space-score",
  ADD_NEW_SPACE_SCORE: "/libraries/space-score/add-new",
  VISIBILITY_ANGLES_TABLE: "/libraries/visibility-angles",
  ADD_NEW_VISIBILITY_ANGLES: "/libraries/visibility-angles/add-new",
  DISTANCES_TABLE: "/libraries/distances",
  ADD_NEW_DISTANCE: "/libraries/distances/add-new",
  DESIGN_TYPES: "/libraries/design-types",
  ADD_NEW_DESIGN_TYPE: "/libraries/design-types/add-new",
  MEDIUM_MATERIAL_TYPES_TABLE: "/libraries/material-types",
  ADD_NEW_MEDIUM_MATERIAL_TYPE: "/libraries/material-types/add-new",
  WORK_TYPES_TABLE: "/libraries/work-types",
  ADD_NEW_WORK_TYPE: "/libraries/work-types/add-new",
  PARTIES_TABLE: "/libraries/parties",
  ADD_NEW_PARTY: "/libraries/parties/add-new",
  ADD_NEW_PARTY_CONTACTS: "/libraries/parties/add-new/contact",
  INVENTORY_TABLE: "/ooh/inventory",
  ADD_NEW_INVENTORY: "/ooh/inventory/add-new",
  CITIES_TABLE: "/libraries/cities",
  ADD_NEW_CITY: "/libraries/cities/add-new",
  LOCATION_TABLE: "/libraries/locations",
  ADD_NEW_LOCATION: "/libraries/locations/add-new",
  LOCATION_ADDRESSES_TABLE: "/libraries/location-addresses",
  ADD_NEW_LOCATION_ADDRESS: "/libraries/location-addresses/add-new",
  SCHOOLS_TABLE: "/libraries/schools",
  ADD_NEW_SCHOOL: "/libraries/schools/add-new",
  HORECA_TABLE: "/libraries/horeca",
  ADD_NEW_HORECA: "/libraries/horeca/add-new",
  CONTACTS_TABLE: "/libraries/contacts",
  ADD_NEW_CONTACT: "/libraries/contacts/add-new",
  VENDORS_TABLE: "/libraries/vendors",
  ADD_NEW_VENDOR: "/libraries/vendors/add-new",
  ADD_NEW_VENDOR_CONTACT: "/libraries/vendors/add-new/contact",
  AGENCIES_TABLE: "/libraries/agencies",
  ADD_NEW_AGENCY: "/libraries/agencies/add-new",
  ADD_NEW_AGENCY_CONTACT: "/libraries/agencies/add-new/contact",
  CLIENTS_TABLE: "/libraries/clients",
  ADD_NEW_CLIENT: "/libraries/clients/add-new",
  ADD_NEW_CLIENT_CONTACT: "/libraries/clients/add-new/contact",
  PARTNERS_TABLE: "/libraries/partners",
  ADD_NEW_PARTNER: "/libraries/partners/add-new",
  ADD_NEW_PARTNER_CONTACT: "/libraries/partners/add-new/contact",
  AUTHORITIES_TABLE: "/libraries/authorities",
  ADD_NEW_AUTHORITY: "/libraries/authorities/add-new",
  ADD_NEW_AUTHORITY_CONTACT: "/libraries/authorities/add-new/contact",
};

const FalconRoutes = () => {
  return (
    <Routes>
      {/* <Route path="landing" element={<Landing />} /> */}
      <Route element={<ErrorLayout />}>
        <Route path="errors/404" element={<Error404 />} />
        <Route path="errors/500" element={<Error500 />} />
      </Route>
      {/*- ------------- Authentication ---------------------------  */}

      {/*- ------------- simple ---------------------------  */}
      <Route element={<AuthSimpleLayout />}>
        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_LOGIN}
          element={<SimpleLogin />}
        />
        {/* <Route
          path="authentication/register"
          element={<SimpleRegistration />}
        /> */}
        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_FORGOT_PASSWORD}
          element={<SimpleForgetPassword />}
        />

        <Route
          path={CONSTANT_ROUTES.AUTHENTICATION_RESET_PASSWORD}
          element={<SimplePasswordReset />}
        />
        {/* <Route
          path="authentication/lock-screen"
          element={<SimpleLockScreen />}
        />
        <Route path="authentication/logout" element={<SimpleLogout />} />
       
   
        <Route
          path="authentication/confirm-mail"
          element={<SimpleConfirmMail />}
        /> */}
      </Route>

      {/* //--- MainLayout Starts  */}

      <Route element={<AuthGuard />}>
        <Route element={<MainLayout />}>
          {/*Dashboard*/}
          <Route path="/" element={<Dashboard />} />

          {/* =============Libraries=============== */}
          <Route path="/libraries/categories" element={<CategoriesTable />} />
          <Route
            path="/libraries/categories/add-new-category"
            element={<AddCategory />}
          />
          <Route path="/libraries/classes" element={<ClassesTable />} />
          <Route
            path="/libraries/classes/add-new-class"
            element={<AddClass />}
          />
          <Route path="/libraries/channels" element={<ChannelTable />} />
          <Route
            path="/libraries/channels/add-new-channel"
            element={<AddChannel />}
          />
          <Route path="/libraries/sizes" element={<SizesTable />} />
          <Route path="/libraries/sizes/add-new-size" element={<AddSize />} />
          <Route path="/libraries/uom" element={<UnitOfMeasurementTable />} />
          <Route path="/libraries/uom/add-new-unit" element={<AddUOM />} />
          <Route path="/libraries/places" element={<PlacesTable />} />
          <Route
            path="/libraries/places/add-new-place"
            element={<AddPlace />}
          />
          <Route path="/libraries/regions" element={<RegionsTable />} />
          <Route
            path="/libraries/regions/add-new-region"
            element={<AddRegion />}
          />
          <Route
            path={CONSTANT_ROUTES.CITIES_TABLE}
            element={<CitiesTable />}
          />
          <Route path={CONSTANT_ROUTES.ADD_NEW_CITY} element={<AddCity />} />
          <Route
            path={CONSTANT_ROUTES.LOCATION_TABLE}
            element={<LocationsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_LOCATION}
            element={<AddLocation />}
          />
          <Route
            path={CONSTANT_ROUTES.LOCATION_ADDRESSES_TABLE}
            element={<LocationsAddressesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_LOCATION_ADDRESS}
            element={<AddLocationAddress />}
          />
          <Route
            path={CONSTANT_ROUTES.SCHOOLS_TABLE}
            element={<SchoolsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_SCHOOL}
            element={<AddSchool />}
          />
          <Route
            path={CONSTANT_ROUTES.HORECA_TABLE}
            element={<HorecaTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_HORECA}
            element={<AddHoreca />}
          />
          <Route
            path="/libraries/authorities-rate-list"
            element={<AuthoritiesRateListTable />}
          />
          <Route
            path="/libraries/authorities-rate-list/add-new-authorities-rate-list"
            element={<AddAuthoritiesRateList />}
          />
          <Route
            path={CONSTANT_ROUTES.CONTACTS_TABLE}
            element={<ContactsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_CONTACT}
            element={<AddContact />}
          />
          <Route
            path={CONSTANT_ROUTES.AUTHORITIES_TABLE}
            element={<AuthoritiesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_AUTHORITY}
            element={<AddAuthority />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_AUTHORITY_CONTACT}
            element={<AddAuthorityContact />}
          />
          <Route path="/libraries/space-types" element={<SpaceTypesTable />} />
          <Route
            path="/libraries/space-types/add-new-space"
            element={<AddSpaceType />}
          />
          <Route
            path={CONSTANT_ROUTES.MARKETS_TABLE}
            element={<MarketsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_MARKETS}
            element={<AddMarket />}
          />
          <Route
            path={CONSTANT_ROUTES.SPACE_ADDRESSES_TABLE}
            element={<SpaceAddressesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_SPACE_ADDRESS}
            element={<AddSpaceAddress />}
          />
          <Route
            path={CONSTANT_ROUTES.STATUES_TABLE}
            element={<StatusesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_STATUS}
            element={<AddStatus />}
          />
          <Route
            path={CONSTANT_ROUTES.MEDIUMS_TABLE}
            element={<MediumsTableSecond />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_MEDIUM}
            element={<AddMediumSecond />}
          />
          <Route
            path={CONSTANT_ROUTES.VISIBILITY_TABLE}
            element={<VisibilityTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_VISIBILITY}
            element={<AddVisibility />}
          />
          <Route
            path={CONSTANT_ROUTES.SPACE_CLASSES_TABLE}
            element={<SpaceClassesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_SPACE_CLASS}
            element={<AddSpaceClass />}
          />
          <Route
            path={CONSTANT_ROUTES.SPACE_SCORE_TABLE}
            element={<SpaceScoreTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_SPACE_SCORE}
            element={<AddSpaceScore />}
          />
          <Route
            path={CONSTANT_ROUTES.VISIBILITY_ANGLES_TABLE}
            element={<VisibilityAnglesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_VISIBILITY_ANGLES}
            element={<AddVisibilityAngle />}
          />
          <Route
            path={CONSTANT_ROUTES.DISTANCES_TABLE}
            element={<DistancesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_DISTANCE}
            element={<AddDistance />}
          />
          <Route
            path={CONSTANT_ROUTES.DESIGN_TYPES}
            element={<DesignTypesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_DESIGN_TYPE}
            element={<AddDesignType />}
          />
          <Route
            path={CONSTANT_ROUTES.MEDIUM_MATERIAL_TYPES_TABLE}
            element={<MediumMaterialTypesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_MEDIUM_MATERIAL_TYPE}
            element={<AddMediumMaterialType />}
          />
          <Route
            path={CONSTANT_ROUTES.WORK_TYPES_TABLE}
            element={<WorkTypesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_WORK_TYPE}
            element={<AddWorkType />}
          />
          {/* <Route
            path={CONSTANT_ROUTES.PARTIES_TABLE}
            element={<PartiesTable />}
          />
          <Route path={CONSTANT_ROUTES.ADD_NEW_PARTY} element={<AddParty />} />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_PARTY_CONTACTS}
            element={<AddPartyContact />}
          /> */}
          <Route
            path={CONSTANT_ROUTES.INVENTORY_TABLE}
            element={<InventoryTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_INVENTORY}
            element={<AddInventory />}
          />

          <Route
            path={CONSTANT_ROUTES.VENDORS_TABLE}
            element={<VendorsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_VENDOR}
            element={<AddVendor />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_VENDOR_CONTACT}
            element={<AddVendorContact />}
          />
          <Route
            path={CONSTANT_ROUTES.AGENCIES_TABLE}
            element={<AgenciesTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_AGENCY}
            element={<AddAgency />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_AGENCY_CONTACT}
            element={<AddAgencyContact />}
          />
          <Route
            path={CONSTANT_ROUTES.CLIENTS_TABLE}
            element={<ClientsTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_CLIENT}
            element={<AddClient />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_CLIENT_CONTACT}
            element={<AddClientContact />}
          />
          <Route
            path={CONSTANT_ROUTES.PARTNERS_TABLE}
            element={<PartnersTable />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_PARTNER}
            element={<AddPartner />}
          />
          <Route
            path={CONSTANT_ROUTES.ADD_NEW_PARTNER_CONTACT}
            element={<AddPartnerContact />}
          />
          <Route path="/business-units" element={<BusinessUnitsTable />} />
          <Route
            path="/business-units/add-new-business-unit"
            element={<AddBusinessUnit />}
          />
          <Route path="/brand-groups" element={<BrandGroupsTable />} />
          <Route
            path="/brand-groups/add-new-brand-group"
            element={<AddBrandGroups />}
          />
          <Route path="/brans" element={<BransTable />} />
          <Route path="/brans/add-new-brans" element={<AddBrans />} />
          <Route path="/stores" element={<StoresTable />} />
          <Route path="/stores/add-new-store" element={<AddStore />} />
          <Route path="/sites" element={<SitesTable />} />
          <Route path="/sites/add-new-site" element={<AddSite />} />
          <Route path="/venues" element={<VenueTable />} />
          <Route path="/venues/add-new-venue" element={<AddVenue />} />
          <Route path="/mediums" element={<MediumsTable />} />
          <Route path="/mediums/add-new-medium" element={<AddMedium />} />
          <Route path="/drivers" element={<DriversTable />} />
          <Route path="/drivers/add-new-driver" element={<AddDriver />} />
          <Route path="/vehicles" element={<VehiclesTable />} />
          <Route path="/vehicles/add-new-vehicle" element={<AddVehicle />} />
          {/* ===============Libraries End================ */}

          {/* =========================Inventory=========================== */}

          <Route path="/products" element={<ProductsTable />} />
          <Route path="/products/add-new-product" element={<AddProduct />} />
          {/*============================Inventory End=========================  */}
          {/*============================Human Resource=========================  */}
          <Route path="/promoters" element={<PromotersOrHRTable />} />
          <Route path="/promoters/add-new-promoter" element={<AddPromoter />} />
          <Route path="/attendances" element={<AttendancesTable />} />
          <Route
            path="/attendances/add-new-attendance"
            element={<AddAttendance />}
          />
          {/*============================Human Resource End=========================  */}
          {/*============================Administration=========================  */}

          <Route path="/groups" element={<GroupsTable />} />
          <Route path="/groups/add-new-group" element={<AddGroup />} />
          <Route
            path="/administration/roles-and-permissions"
            element={<RolesAndPermissionsTable />}
          />
          <Route
            path="/administration/roles-and-permissions/add-new-role"
            element={<AddRoleAndPermission />}
          />
          <Route
            path="/administration/roles-and-permissions/assignment"
            element={<AddAssignment />}
          />
          <Route path="/administration/users" element={<UsersTable />} />
          <Route
            path="/administration/users/add-new-user"
            element={<AddUser />}
          />
          <Route path="/infinite-scroll" element={<AgGridInfiniteScroll />} />
          <Route path="/map" element={<GoogleMapExample />} />

          {/*============================Administration End=========================  */}

          {/*=======Pages  ======*/}

          <Route path={CONSTANT_ROUTES.USERPROFILE} element={<Profile />} />
          <Route path={"/calender"} element={<Calendar />} />
          <Route path={"/timeline"} element={<Timeline />} />
          <Route path={"/tree-view"} element={<TreeViewFalconExample />} />
          <Route path={"/dynamic-form"} element={<DynamicForm />} />
          <Route path={"/dnd-example"} element={<DndExampleComponent />} />
        </Route>
      </Route>

      {/* //--- MainLayout end  */}

      {/* <Navigate to="/errors/404" /> */}
      <Route path="*" element={<Navigate to="/errors/404" replace />} />
    </Routes>
  );
};

export default FalconRoutes;
