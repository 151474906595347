import { useQuery } from "@tanstack/react-query";
import { LibraryAPI } from "api";

const fetchSpaceSize = () => {
  return LibraryAPI.get({ params: { library: 3 } });
};
export const useFetchSpaceSize = () => {
  return useQuery({
    queryKey: ["space-size"],
    queryFn: fetchSpaceSize,
  });
};
