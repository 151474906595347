import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import ContactsInformation from "./ContactsInformation";
import { useNavigate } from "react-router-dom";
import useQuery from "hooks/useQuery";
import BankInformation from "./BankInformation";
import MoreInformation from "./MoreInformation";
import { useLocation } from "react-router-dom";

const AddDriverBasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (formId) {
      setUpdate(true);
    }
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoFocus
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Father / Guardian"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.father}
                  {...register("father")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.father?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="CNIC"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.CNIC}
                  {...register("CNIC")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.CNIC?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Date of Birth"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.dob}
                  {...register("dob")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.dob?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel controlId="floatingInput" label="Age" className="">
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.age}
                  {...register("age")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.age?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Profile Picture</Form.Label>
              <Form.Control
                type="file"
                size="lg"
                isInvalid={!!errors.profilePicture}
                {...register("profilePicture")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.profilePicture?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
      <ContactsInformation />
      <BankInformation />
      <MoreInformation />

      {/* <Row className="gx-2 gy-3 px-3 mb-3 ">
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className="text-secondary fw-medium p-0 me-3"
            type="button"
            onClick={() => navigate(-1)}
          >
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Ok
          </Button>
        </Col>
      </Row> */}
    </Card>
  );
};

export default AddDriverBasicInformation;
