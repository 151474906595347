import React from "react";
import { useDroppable } from "@dnd-kit/core";

const Droppable = ({ children }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: "droppable",
  });

  const style = {
    border: isOver ? "2px solid green" : "2px dashed gray",
    padding: "16px",
    minHeight: "500px",
    minWidth: "800px",
    backgroundColor: isOver ? "#f0f8ff" : "#f9f9f9",
  };

  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
};

export default Droppable;
// Droppable.jsx
// import React from "react";
// import { useDroppable } from "@dnd-kit/core";

// const Droppable = ({ children }) => {
//   const { setNodeRef, isOver } = useDroppable({
//     id: "droppable",
//   });

//   const style = {
//     minHeight: "200px",
//     minWidth: "900px",
//     padding: "16px",
//     backgroundColor: isOver ? "#e6f7ff" : "#f0f0f0",
//     border: isOver ? "2px dashed #1890ff" : "2px dashed #ccc",
//     borderRadius: "4px",
//   };

//   return (
//     <div ref={setNodeRef} style={style}>
//       {children}
//     </div>
//   );
// };

// export default Droppable;
