// import React from "react";
// import { useSortable } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";

// const SortableItem = ({ id, children, onDelete }) => {
//   const {
//     attributes,
//     listeners,
//     setNodeRef,
//     transform,
//     transition,
//     isDragging,
//   } = useSortable({ id });

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition: transition,
//   };

//   return (
//     <div ref={setNodeRef} {...attributes} {...listeners} style={style}>
//       {children}
//     </div>
//   );
// };

// export default SortableItem;

// import React, { useState } from "react";
// import { useSortable } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";

// const SortableItem = ({ id, children, onDelete, onApplyCSS }) => {
//   const {
//     attributes,
//     listeners,
//     setNodeRef,
//     transform,
//     transition,
//     isDragging,
//   } = useSortable({ id });

//   const [isHovered, setIsHovered] = useState(false);

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition: transition || "transform 250ms ease",
//     zIndex: isDragging ? 999 : "auto",
//     position: "relative",
//   };

//   const handleDeleteClick = (event) => {
//     // Prevents triggering drag when clicking delete
//     console.log("Delete button clicked for id:", id); // Debugging log
//     onDelete(id);
//   };

//   const handleApplyCSSClick = (event) => {
//     event.stopPropagation(); // Prevents triggering drag when clicking apply CSS
//     if (onApplyCSS) {
//       onApplyCSS(id);
//     } else {
//       console.warn("onApplyCSS function is not defined"); // Debugging log
//     }
//   };

//   return (
//     <div
//       ref={setNodeRef}
//       style={style}
//       {...attributes}
//       {...listeners}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {children}
//       {isHovered && (
//         <div style={{ position: "absolute", top: 0, right: 0 }}>
//           <button style={{ margin: "0 5px" }} onClick={handleApplyCSSClick}>
//             Apply CSS
//           </button>
//           <button onClick={(e) => handleDeleteClick(e)}>Delete</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SortableItem;

// import React, { useState } from "react";
// import { useSortable } from "@dnd-kit/sortable";
// import { CSS } from "@dnd-kit/utilities";

// const SortableItem = ({ id, children, onDelete, onApplyCSS }) => {
//   const {
//     attributes,
//     listeners,
//     setNodeRef,
//     transform,
//     transition,
//     isDragging,
//   } = useSortable({ id });

//   const [isHovered, setIsHovered] = useState(false);

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition: transition || "transform 250ms ease",
//     zIndex: isDragging ? 999 : "auto",
//     position: "relative",
//   };

//   const handleDeleteClick = (event) => {
//     event.stopPropagation(); // Prevents triggering drag when clicking delete
//     console.log("Delete button clicked for id:", id); // Debugging log
//     onDelete(id);
//   };

//   const handleApplyCSSClick = (event) => {
//     event.stopPropagation(); // Prevents triggering drag when clicking apply CSS
//     console.log("Apply CSS button clicked for id:", id); // Debugging log
//     if (onApplyCSS) {
//       onApplyCSS(id);
//     } else {
//       console.warn("onApplyCSS function is not defined"); // Debugging log
//     }
//   };

//   return (
//     <div
//       ref={setNodeRef}
//       style={style}
//       {...attributes}
//       {...listeners}
//       onMouseEnter={() => setIsHovered(true)}
//       onMouseLeave={() => setIsHovered(false)}
//     >
//       {children}
//       {isHovered && (
//         <div style={{ position: "absolute", top: 0, right: 0 }}>
//           <button style={{ margin: "0 5px" }} onClick={handleApplyCSSClick}>
//             Apply CSS
//           </button>
//           <button onClick={handleDeleteClick}>Delete</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default SortableItem;
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { useState } from "react";
import { GoGear } from "react-icons/go";
import { RiDeleteBinLine } from "react-icons/ri";
import AddSettingModal from "./SettingModal";
const SortableItem = ({ id, children, onDelete, onApplyCSS }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: "relative",
    zIndex: isHovered ? 1 : 0, // Ensuring the hover buttons are above other elements
  };

  const handleDeleteClick = (event) => {
    event.preventDefault(); // Prevents triggering drag when clicking delete
    onDelete(id);
  };

  const handleApplyCSSClick = (event) => {
    event.preventDefault(); // Prevents triggering drag when clicking apply CSS
    onApplyCSS(id);
  };

  return (
    <>
      <div
        ref={setNodeRef}
        style={style}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          {...(isFocused ? {} : attributes)}
          {...(isFocused ? {} : listeners)} // Disable drag listeners when input is focused
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          {children}
        </div>
        {isHovered && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          >
            <GoGear
              className="cursor-pointer"
              size={20}
              onClick={handleApplyCSSClick}
            />
            <RiDeleteBinLine
              className="cursor-pointer"
              size={20}
              onClick={handleDeleteClick}
            />
          </div>
        )}
      </div>
      {/* <AddSettingModal isOpenModal={isOpenModal} setIsOpenModal={onClose} /> */}
    </>
  );
};

export default SortableItem;
