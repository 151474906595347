import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const AddAuthoritiesRateListBasicInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (formId) {
      setUpdate(true);
    }
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoFocus
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              {/* <Form.Label>First name:</Form.Label> */}
              <FloatingLabel
                controlId="floatingInput"
                label="Description"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.description}
                  {...register("description")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Authority">
                <Form.Select
                  aria-label="authority"
                  isInvalid={!!errors.authority}
                  {...register("authority")}
                >
                  <option value="">Select</option>
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.authority?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Category">
                <Form.Select
                  aria-label="category"
                  isInvalid={!!errors.category}
                  {...register("category")}
                >
                  <option value="">Select</option>
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.category?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Location">
                <Form.Select
                  aria-label="location"
                  isInvalid={!!errors.location}
                  {...register("location")}
                >
                  <option value="">Select</option>
                  {/* <option value="User">User</option>
                  <option value="Admin">Admin</option> */}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.location?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Tax Rate">
                <Form.Select
                  aria-label="taxRate"
                  isInvalid={!!errors.taxRate}
                  {...register("taxRate")}
                >
                  <option value="">Select</option>
                  <option value="Per Week">Per Week</option>
                  <option value="Per Two Weeks">Per Two Weeks</option>
                  <option value="Per Month">Per Month</option>
                  <option value="Per Year">Per Year</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.taxRate?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Active"
              defaultChecked={!state}
              isInvalid={!!errors.active}
              {...register("active")}
            />
          </Col>

          {/*   <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="City">
                <Form.Select
                  aria-label="city"
                  isInvalid={!!errors.city}
                  {...register("city")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.city?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        {/* <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
           OK
            </Button>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default AddAuthoritiesRateListBasicInformation;
