import { LibraryAPI } from "api";
import { useFetchDesignType } from "hooks/useFetchDesignType";
import { useFetchMaterialType } from "hooks/useFetchMaterialType";
import { useFetchSpaceSize } from "hooks/useFetchSpaceSize";
import { useFetchVendors } from "hooks/useFetchVendors";
import { useFetchWorkType } from "hooks/useFetchWorkType";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  FloatingLabel,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const CostingInformation = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const formId = useQuery().get("id");

  const {
    data: vendors,
    isFetching: isVendorFetching,
    isLoading: isVendorsLoading,
  } = useFetchVendors();

  const {
    data: designTypes,
    isFetching: isDesignTypeFetching,
    isLoading: isDesignTypeLoading,
  } = useFetchDesignType();

  const {
    data: materialType,
    isFetching: isMaterialTypeFetching,
    isLoading: isMaterialTypeLoading,
  } = useFetchMaterialType();

  const {
    data: workType,
    isFetching: isWorkTypeFetching,
    isLoading: isWorkTypeLoading,
  } = useFetchWorkType();

  const {
    data: spaceSize,
    isLoading: isLoadingSpaceSize,
    isFetching: isFetchingSpaceSize,
  } = useFetchSpaceSize();

  if (
    isVendorsLoading ||
    isDesignTypeLoading ||
    isLoadingSpaceSize ||
    isWorkTypeLoading ||
    isMaterialTypeLoading
  ) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <>
      <Card.Header as="h6" className="bg-body-tertiary mb-2">
        Design Charges
      </Card.Header>
      <Row className="gx-2 gy-3">
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Design Type">
              <Form.Select
                aria-label="designType"
                autoFocus
                isInvalid={!!errors?.designCharges?.type}
                {...register("designCharges.type")}
              >
                <option value="">Select</option>
                {designTypes?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.designCharges?.type?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Vendor">
              <Form.Select
                aria-label="vendor"
                isInvalid={!!errors?.designCharges?.vendor}
                {...register("designCharges.vendor")}
              >
                <option value="">Select</option>

                {vendors?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}

                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.designCharges?.vendor?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel controlId="floatingInput" label="Price" className="">
              <Form.Control
                type="number"
                placeholder=""
                isInvalid={!!errors?.designCharges?.price}
                {...register("designCharges.price")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.designCharges?.price?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Card.Header as="h6" className="bg-body-tertiary mb-2">
          Printing Charges
        </Card.Header>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Material Type">
              <Form.Select
                aria-label="materialType"
                isInvalid={!!errors?.printingCharges?.type}
                {...register("printingCharges.type")}
              >
                <option value="">Select</option>
                {materialType?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.printingCharges?.type?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Size">
              <Form.Select
                aria-label="size"
                isInvalid={!!errors?.printingCharges?.attribute1}
                {...register("printingCharges.attribute1")}
              >
                <option value="">Select</option>
                {spaceSize?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {Number(d.phone) * Number(d.email)}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.printingCharges?.attribute1?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Vendor">
              <Form.Select
                aria-label="vendor"
                isInvalid={!!errors?.printingCharges?.vendor}
                {...register("printingCharges.vendor")}
              >
                <option value="">Select</option>
                {vendors?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}

                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.printingCharges?.vendor?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel controlId="floatingInput" label="Price" className="">
              <Form.Control
                type="number"
                placeholder=""
                isInvalid={!!errors?.printingCharges?.price}
                {...register("printingCharges.price")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.printingCharges?.price?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Card.Header as="h6" className="bg-body-tertiary mb-2">
          Installation Charges
        </Card.Header>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Work Type">
              <Form.Select
                aria-label="workType"
                isInvalid={!!errors?.installationCharges?.attribute1}
                {...register("installationCharges.attribute1")}
              >
                <option value="">Select</option>
                {workType?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.installationCharges?.attribute1?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Material Type">
              <Form.Select
                aria-label="materialType"
                isInvalid={!!errors?.installationCharges?.type}
                {...register("installationCharges.type")}
              >
                <option value="">Select</option>
                {materialType?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}
                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.installationCharges?.type?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Vendor">
              <Form.Select
                aria-label="vendor"
                isInvalid={!!errors?.installationCharges?.vendor}
                {...register("installationCharges.vendor")}
              >
                <option value="">Select</option>
                {vendors?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}

                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.installationCharges?.vendor?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel controlId="floatingInput" label="Price" className="">
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.installationCharges?.price}
                {...register("installationCharges.price")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.installationCharges?.price?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>

        <Card.Header as="h6" className="bg-body-tertiary mb-2">
          Electricity Charges
        </Card.Header>

        <Col md="4">
          <Form.Group>
            <FloatingLabel controlId="floatingSelectGrid" label="Vendor">
              <Form.Select
                aria-label="vendor"
                isInvalid={!!errors?.electricityCharges?.vendor}
                {...register("electricityCharges.vendor")}
              >
                <option value="">Select</option>
                {vendors?.data.data.map((d) => (
                  <option key={d.id} value={d.id}>
                    {d.name}
                  </option>
                ))}

                {/* <option value="User">User</option>
                <option value="Admin">Admin</option> */}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors?.electricityCharges?.vendor?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
        <Col md="4">
          <Form.Group>
            {/* <Form.Label>First name:</Form.Label> */}
            <FloatingLabel
              controlId="floatingInput"
              label="Price Per Night"
              className=""
            >
              <Form.Control
                type="text"
                placeholder=""
                isInvalid={!!errors?.electricityCharges?.price}
                {...register("electricityCharges.price")}
              />
              <Form.Control.Feedback type="invalid">
                {errors?.electricityCharges?.price?.message}
              </Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>
        </Col>
      </Row>
      {/* <Row className="gx-2 gy-3 my-1">
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className="text-secondary fw-medium p-0 me-3"
            type="button"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button variant="primary" type="submit">
            Ok
          </Button>
        </Col>
      </Row> */}
    </>
  );
};

export default CostingInformation;
