import MultiSelect from "components/common/MultiSelect";
import Select from "react-select";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ChannelBasicInformation = () => {
  const {
    register,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const navigate = useNavigate();
  const { state } = useLocation();
  const formId = useQuery().get("id");

  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (formId) {
      setUpdate(true);
    }
  }, []);

  const categoryOptions = [
    { value: "Outdoor", label: "Outdoor" },
    { value: "Retail", label: "Retail" },
  ];

  useEffect(() => {
    register("category", { required: true });
  }, [register]);

  useEffect(() => {
    if (state) {
      setValue("category", state.category);
    }
  }, [state, setValue]);

  const handleSelectChange = (selectedOption) => {
    setValue("category", selectedOption ? selectedOption : "");
    if (selectedOption) {
      clearErrors("category");
    }
  };

  const handleInputFocus = () => {
    clearErrors("category");
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: errors.category ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: errors.category ? "red" : provided["&:hover"].borderColor,
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black", // Set the text color of the selected value to black
      padding: "7px",
    }),
    placeholder: (provided) => ({
      ...provided,
      padding: "7px",
    }),
    input: (provided) => ({
      ...provided,
      color: "black",
      paddingTop: "12px",
      paddingBottom: "12px",
      paddingLeft: "7px",
      paddingRight: "7px",
      // padding: "12px", // Set the input text color to black
    }),

    menu: (provided) => ({
      ...provided,
      color: "black",
      zIndex: 9999, // Adjust the zIndex as needed
    }),
  };

  let initialSelectedOption = categoryOptions.find(
    (option) => option.value === state?.category
  );

  if (initialSelectedOption == undefined) {
    initialSelectedOption = state
      ? { label: state?.category, value: state?.category }
      : undefined;
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h6" className="bg-body-tertiary">
        Basic information
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Select
                options={categoryOptions}
                isClearable
                placeholder={
                  <span>
                    Category{" "}
                    {/* <span style={{ color: "red", fontSize: "12px" }}>*</span> */}
                  </span>
                }
                className={`${errors.category ? "is-invalid" : ""}`}
                styles={customStyles}
                autoFocus
                isMulti
                onChange={handleSelectChange}
                onFocus={handleInputFocus}
                onInputChange={handleInputFocus}
                defaultValue={initialSelectedOption}
              />
              <Form.Control.Feedback type="invalid">
                {errors.category?.message}
              </Form.Control.Feedback>
              {/* <Controller
                name="category"
                render={({ field, ref }) => (
                  <MultiSelect
                    ref={ref}
                    closeMenuOnSelect={false}
                    isMulti
                    options={categoryOptions}
                    className={`${errors.category ? "is-invalid" : ""}`}
                    {...field}
                  />
                )}
                control={control}
              />
              <Form.Control.Feedback type="invalid">
                {errors.category?.message}
              </Form.Control.Feedback> */}
            </Form.Group>
            {/* <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Type"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder=""
                  autoFocus
                  isInvalid={!!errors.type}
                  {...register("type")}
                />

                <Form.Control.Feedback type="invalid">
                  {errors.type?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group> */}
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Sub Category"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.subCategory}
                  {...register("subCategory")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.subCategory?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label=" Sub Sub Category"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.subSubCategory}
                  {...register("subSubCategory")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.subSubCategory?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label=" Category Title"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.categoryTitle}
                  {...register("categoryTitle")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.categoryTitle?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Name"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.name}
                  {...register("name")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
          <Col md="12">
            <Form.Check
              type="switch"
              id="defaultSwitch"
              label="Active"
              defaultChecked={!state}
              isInvalid={!!errors.active}
              {...register("active")}
            />
          </Col>
          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="name@example.com"
                  isInvalid={!!errors.email}
                  {...register("email")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Username"
                className=""
              >
                <Form.Control
                  type="text"
                  placeholder="Username"
                  isInvalid={!!errors.userName}
                  {...register("userName")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.userName?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  placeholder="password"
                  isInvalid={!!errors.password}
                  {...register("password")}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}

          {/* <Col md="12">
            <Form.Group>
              <FloatingLabel controlId="floatingSelectGrid" label="Role">
                <Form.Select
                  aria-label="role"
                  isInvalid={!!errors.role}
                  {...register("role")}
                >
                  <option value="">Select</option>
                  <option value="User">User</option>
                  <option value="Admin">Admin</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.role?.message}
                </Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Col> */}
        </Row>
        {/* <Row className="gx-2 gy-3 my-1">
          <Col className="d-flex justify-content-end">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
            <Button variant="primary" type="submit">
       
              Ok
            </Button>
          </Col>
        </Row> */}
      </Card.Body>
    </Card>
  );
};

export default ChannelBasicInformation;
